footer
    background: #f7f8fc
    color: $color-text-light
    width: 100%
    min-width: 200px
    position: relative
    padding-top: 35px
    z-index: 3
    a, a[href]
        &:hover
            color: $color-text

    .company
        flex-grow: 1
        .logo-wrapper
            height: 68px
            text-align: left
            position: absolute
            top: 0
            .logo
                width: 130px
                height: 69px
                display: inline-block
                background: url("/images/logo/logo-small-gray.png")
                background-size: cover
                filter: brightness(0.65)
                &:hover
                    filter: brightness(0.75)

    section
        display: flex
        justify-content: space-between

    .column
        max-width: 265px
        width: 100%
        h3
            font-size: 12px
            margin-bottom: 19px
            text-transform: uppercase
            color: $color-text
        li
            position: relative
            font-size: 14px
            line-height: 18px
            margin-bottom: 18px
        span
            color: $color-text

    .payments
        line-height: 0
        padding-bottom: 30px
        margin-top: -60px
        img
            height: 40px
            background: #ffffff
            margin-right: 13px
            &.interval
                margin-right: 18px
            &.interval-big
                margin-right: 25px
            &.near
                margin-right: 5px
            &.transparent
                background: transparent
            &:last-child
                margin-right: 0

    .copyright
        line-height: 14px
        font-weight: 300
        padding-top: 20px
        padding-bottom: 20px
        font-size: 12px

    .service-info
        font-size: 10px
        span
            margin-right: 5px
            color: $color-text-light

    @include laptop
        padding-top: 90px
        padding-left: 20px
        padding-right: 20px
        .company
            max-width: 0
        .middle
            flex-direction: column
            justify-content: flex-start
        .copyright
            display: flex
            flex-direction: column-reverse
            margin: 0 auto
            div
                margin-bottom: 20px
        .company
            .logo-wrapper
                left: 50%
                transform: translateX(-50%)

        .payments
            margin-top: 20px
            padding-bottom: 10px

    @include tablet
        margin-top: 30vh
        section
            flex-direction: column
        .column
            margin-bottom: 30px
            &:nth-child(2)
                order: 1
        ul
            justify-content: center
            flex-wrap: wrap
            li:last-child
                margin-right: 0 !important
        .copyright
            max-width: 300px
        .payments
            text-align: center
        .contact
            width: 280px
            margin: 40px auto 0
