.gallery
    width: 100%
    height: 414px
    display: flex
    justify-content: space-between
    position: relative
    .big
        width: 49.5%
        height: 400px
        position: relative
        margin-top: 5px
        border-top-left-radius: 15px
        border-bottom-left-radius: 15px
        overflow: hidden
        @include tablet
            display: none
    .thumbs
        height: 400px
        width: 49.5%
        overflow: hidden
        display: flex
        position: relative
        @include tablet
            width: 100%
        &.scroll
            overflow-x: scroll
            height: 423px
            flex-direction: column
            flex-wrap: wrap
            &::-webkit-scrollbar
                height: 5px
                line-height: 0
            &::-webkit-scrollbar-track
                background: transparent
            &::-webkit-scrollbar-thumb
                background-color: $color-brand
        .item
            cursor: pointer
            display: inline-block
            position: relative
            min-width: 49%
            max-width: 49%
            height: 195px
            margin: 5px 2% 5px 0
            &:last-child
                margin-bottom: 0
            @include except-phone
                &:nth-child(1)
                    order: 1
    .sizer
        background: $color-not-loaded center center no-repeat
        background-size: cover
        width: 100%
        height: 100%
        justify-content: center
        align-items: center
        overflow: hidden
