.pager
    display: flex
    justify-content: flex-end
    gap: 8px
    margin-bottom: 26px

    @include tablet
        margin-top: 20px
        justify-content: center

    .button
        cursor: pointer
        width: 40px
        height: 40px
        padding-left: 2px
        padding-right: 2px

        &.active
            border-color: $color-brand
            color: $color-brand
            cursor: default
            font-weight: 700
            font-size: 12px

        .rotate-left
            transform: rotate(90deg)

        .rotate-right
            transform: rotate(-90deg)

    .points
        line-height: 28px
        letter-spacing: 1px
