.block.document
    font-size: 16px
    line-height: 22px
    padding-top: 80px
    padding-bottom: 60px
    max-width: 60rem
    section
        padding-top: 50px
        @include laptop
            padding-right: 50px
        @include tablet
            padding-left: 25px
            padding-right: 25px
    h1
        font-size: 52px
        font-weight: 500
        line-height: 130%
        margin-bottom: 40px
    h2
        margin-bottom: 25px
        font-size: 20px
        line-height: 24px
        font-weight: 300
    h3
        font-size: 19px
        margin-top: 35px
        font-weight: 400
    p
        margin: 25px 0
    p+h2
        margin-top: 55px
    .indent
        margin: 15px 0 20px 21px
    ul, ol
        margin: 15px 0
        list-style: disc
        padding-left: 21px
        padding-bottom: 30px
        ul, ol
            padding-bottom: 0
        li
            list-style: disc
            margin-bottom: 20px
        &.dash
            &, & > li
                list-style: '— '
    ol
        &, & > li
            list-style: upper-latin
        &.digital
            &, & > li
                list-style: decimal
        &.roman
            &, & > li
                list-style: decimal
    .updated
        font-style: italic
        margin: 45px 0 10px
        color: $color-text-light
    .offices
        padding-right: 20px
        .list
            padding-top: 10px
            display: flex
            flex-wrap: nowrap
            div
                padding: 20px 50px 20px 20px
                margin-bottom: 20px
                border-left: 1px solid $color-border
                margin-left: -1px
                h2
                    padding-bottom: 0
                    margin-bottom: 15px
                p
                    margin-top: 15px
                    margin-bottom: 0
            @include laptop
                div
                    padding-right: 20px
            @include tablet
                flex-direction: column

.pdf-button-holder
    margin: 60px 0 0
    .button
        position: relative
