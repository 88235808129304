@keyframes Appear
    0%
        opacity: 0
    100%
        opacity: 1

@keyframes moveDownAndUp
    0%, 100%
        transform: translateY(0)
    50%
        transform: translateY(16px)

html
    scroll-behavior: smooth

body
    color: $color-black
    font-family: -apple-system, BlinkMacSystemFont, Inter, "Helvetica Neue", "Arial Neue", sans-serif , sans-serif
    letter-spacing: 0.232143px
    font-size: 13px
    line-height: 16px
    font-weight: 400

.container
    padding: 0 40px
    @include laptop
        padding: 0 20px

span, p
    color: $color-black

h1
    font-size: 30px
    line-height: 36px

h2
    font-size: 24px
    line-height: 29px
    font-weight: 400
    span
        color: $color-text-light

section
    max-width: 1440px
    width: 100%
    margin: 0 auto
    padding: 0 30px
    @include laptop
        padding: 0 20px
    @include tablet
        padding: 0 10px

.section-wrapper
    margin: 32px auto
    padding: 0

.animated-border
    border-bottom: none
    position: relative

    &::before
        content: ""
        position: absolute
        display: block
        bottom: -2px
        height: 1px
        width: 0
        left: 0
        background-color: currentcolor
        transition: all .2s ease
        transition-delay: .2s

    &::after
        content: ""
        position: absolute
        display: block
        bottom: -2px
        height: 1px
        width: 100%
        right: 0
        background-color: currentcolor
        transition: all .2s ease

    &:hover
        &::before
            width: 100%

        &::after
            width: 0

textarea, pre, input, button
    font-family: inherit
    letter-spacing: inherit

.link, a.link, a[href].link
    cursor: pointer
    color: $color-blue
    &:hover
        color: #074BC5
    &.underlined
        text-decoration: underline

.dark-link
    cursor: pointer
    font-size: 16px
    font-weight: 500
    text-transform: uppercase
    color: $color-black !important
    text-decoration: none

.underlined-text
    transition: border-bottom-color 0.3s ease-in-out
    border-bottom: 1px solid transparent

    &:hover
        border-bottom-color: $color-gray

.hide
    display: none !important

.dual
    display: flex
    width: 100%
    justify-content: space-between
    .second
        text-align: right
    &.column
        flex-direction: column
        .second
            text-align: left

b, strong
    font-weight: 500

.block-wrapper
    min-height: 100vh
    padding-top: 81px
    header
        margin-top: -81px
        position: static
        box-shadow: none !important

.development-block
    position: fixed
    z-index: 100000
    top: 40px
    right: 30px
    .button
        color: mediumorchid
        border-color: mediumorchid
        margin-bottom: 20px

.hide-desktop
    display: none

@include laptop
    .with-search
        .block-wrapper
            padding-top: 161px
    .hide-desktop
        display: block
    .hide-mobile
        display: none

.lg-button
    cursor: pointer
    height: auto
    border-radius: 22px
    padding: 11px 24px
    font-size: 16px
    font-weight: 500
    text-transform: uppercase
    transition: all 0.3s ease-in-out

    &.button-primary
        background-color: $color-primary
        border: 1px solid $color-primary
        color: $color-black

        &:hover
            background-color: $color-black
            border-color: $color-black
            color: $color-white

    &.button-secondary
        background-color: transparent
        border: 1px solid $color-black
        color: $color-black

        &:hover
            background-color: $color-black
            border-color: $color-black
            color: $color-white

    &.button-dark
        background-color: $color-black
        border: 1px solid $color-black
        color: $color-white

        &:hover
            background-color: $color-primary
            border: 1px solid $color-primary
            color: $color-black

.circle-arrow
    width: 32px
    height: 32px
    border-radius: 50%
    background-color: $color-white
    display: flex
    justify-content: center
    align-items: center
    transition: all 0.2s

.move-up-down-arrow
    margin: auto
    animation: moveDownAndUp 3s linear infinite

.moved-arrow-wrapper
    &:hover
        .circle-arrow
            transform: translate(6px, -6px) !important

.block-paddings
    padding: 120px 0

    @include tablet
        padding: 80px 0
