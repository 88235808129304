.text-block
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 20px

    @include laptop
        grid-template-columns: 1fr

    .text-wrapper
        display: flex
        flex-direction: column

    p
        margin-bottom: 46px
        @include tablet
            margin-bottom: 24px

    .icon-plane
        margin: 0 20px
