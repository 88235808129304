.popover-wrapper
    position: relative
    .children-wrapper
        text-decoration: underline
        cursor: pointer
        &:hover
            opacity: 0.8
    .popover
        width: max-content
        min-width: 250px
        opacity: 0
        pointer-events: none
        position: absolute
        top: 30px
        left: 0
        z-index: 100
        background-color: $color-white
        color: $color-text
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15)
        transition: 0.2s all
        border-radius: 2px
        font-size: 14px
        text-align: left
        &[data-open=true]
            opacity: 1
            pointer-events: auto
        .title
            padding: 14px
            border-bottom: 1px solid $color-border
            font-weight: 600
        .content
            padding: 14px
            font-weight: 400
            max-width: 290px
            overflow: hidden
            p
                margin-bottom: 4px
            .list
                font-weight: 600
    .popover-arrow
        position: absolute
        opacity: 0
        top: 11px
        left: 50%
        z-index: 1000
        &[data-open=true]
            opacity: 1
        &:after
            content: ''
            position: absolute
            border: 10px solid transparent
            border-bottom: 10px solid $color-white
