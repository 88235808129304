@import "./base/reset.sass"
@import "./imgs/flagpack/dist/flagpack.css" // Comment this when you need to rebuild external site css bundle

@import "./global.sass"
@import "./icons.sass"
@import "./main.sass"
@import "./print.sass"

@import "./components/accent-frame"
@import "./components/allotment"
@import "./components/billet"
@import "./components/breadcrumbs"
@import "./components/button"
@import "./components/cabinet-header"
@import "./components/calendar"
@import "./components/filters"
@import "./components/flag"
@import "./components/footer"
@import "./components/gallery"
@import "./components/header"
@import "./components/itinerary-notification"
@import "./components/loader"
@import "./components/map"
@import "./components/modal"
@import "./components/navigation"
@import "./components/notifications"
@import "./components/pager"
@import "./components/stars"
@import "./components/status"
@import "./components/table"
@import "./components/copier"
@import "./components/popover"

@import "./block/account"
@import "./block/booking"
@import "./block/cabinet"
@import "./block/details"
@import "./block/document"
@import "./block/error-page"
@import "./block/management"
@import "./block/payment"
@import "./block/room"
@import "./block/search"
@import "./block/search-results"
@import "./block/search-short"
@import "./block/tiles"
@import "./block/agency-search"

@import "./form/complicated-dropdowns"
@import "./form/dropdown"
@import "./form/field"
@import "./form/form"
@import "./form/range-slider"
@import "./form/switch-control"

@import "./landing/index"

// @import "./retail/site.sass" // Uncomment this when you need to rebuild wordpress retail site css bundle
// @import "./odawara/identity.sass" // Uncomment this when you need to rebuild identity css bundle
