.navigation
    position: relative
    background: $color-background
    min-height: 54px
    z-index: 150
    @include desktop
        position: sticky
        top: 80px
        box-shadow: 0 .5px 0 $color-shadow-border
    section
        display: flex
        justify-content: flex-start
        flex-wrap: wrap
        line-height: 24px
        a, .item
            padding: 15px 11px
            margin: 0 5px
            display: flex
            position: relative
            cursor: pointer
            font-size: 13px
            align-items: center
            &:hover
                color: $color-brand
            &.active
                cursor: default
                color: $color-brand
                &:after
                    content: ' '
                    height: 4px
                    left: 8px
                    right: 8px
                    bottom: 0
                    width: auto
                    background: $color-brand
                    background: linear-gradient(180deg, #FFCD0B 0%, #FBAC19 100%)
                    border-radius: 25px
                    position: absolute
    &.linear
        text-align: center
    @include tablet
        &:not(.linear)
            section
                display: block
            &.minified
                .item
                    display: none
                    &.active
                        display: block
                        background: url("./icons/elements/arrow-expand.svg") right 20px center no-repeat
    &.cabinet-second-navigation
        box-shadow: 0 .5px 0 rgba(0, 0, 0, .1)
        background: #FAFAFA
        margin-top: 1px
        section
            padding: 0 5px
            @include tablet
                padding: 0 6px
            max-width: 886px
            a.active
                color: $color-text
