.filters
    h3
        margin-top: 16px
        margin-bottom: 16px
        font-size: 18px
        font-weight: 500
        line-height: 22px
        &.clickable
            cursor: pointer
            display: flex
            justify-content: space-between
            &.static
                cursor: default
            .icon
                width: 24px
                height: 24px
                margin: -4px 0
            @include laptop
                display: none
    h4
        margin-top: -6px
        margin-bottom: 7px
        font-size: 13px
        line-height: 18px
        font-weight: 300
        color: $color-text-light
    .checkbox-wrapper
        margin-bottom: 16px
    .price-range
        margin-bottom: 20px
    .district-count
        font-size: 11px
        color: $color-text-light
    .link
        color: $color-brand
    .input
        height: 44px
        line-height: 42px !important

    .focus
        & > label > .input
            border-color: $color-primary !important
        & > label > .label
            color: $color-primary
            span, p
                color: $color-primary
    @include laptop
        .show-mobile
            display: block

.filtration
    display: flex
    margin-bottom: 3px
    flex-wrap: wrap
    align-items: center
    & > .item
        margin-right: 10px
        margin-bottom: 10px
        position: relative
        display: inline-block
        &.hide-desktop
            @include desktop
                display: none
        .button
            flex-direction: row
            padding: 0 18px
            user-select: none
            .icon
                filter: brightness(0.7) grayscale(1)
            &.after-icon
                padding-right: 12px
                .icon
                    margin-left: 6px
            &.leading-icon
                .icon
                    margin-right: 8px
            &:hover
                .icon
                    filter: none
        .button
            line-height: 32px !important
            height: 32px !important
    .dropdown.select
        min-width: 190px

