.account.block
    margin-top: -81px
    width: 100%
    height: 100%
    min-height: 160vh
    padding-bottom: 60vh
    position: relative
    background: #74B1F5 center bottom no-repeat fixed
    background-size: cover
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    &.white
        background-color: transparent
        padding-bottom: 10vh
        min-height: 110vh
        .section > div
            box-shadow: none
    .small
        max-width: 550px
        .section
            animation: Appear .33s ease-in-out 1
    header
        background: radial-gradient(rgba(255,255,255,0.85), rgba(255,255,255,0) 70%)
        box-shadow: none !important
        position: absolute
        top: -300px
        height: 600px
        padding-top: 299px
        left: 0
        right: 0
        margin-top: 0
        z-index: 1
        .logo-wrapper
            width: 100%
            transform: none
            min-width: 0
            max-width: none
            justify-content: space-around

    section.section
        position: relative
        z-index: 2 !important
        margin: 100px 0
        .link
            border-bottom: 1px solid

    .section
        max-width: 700px
        padding: 20px
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        animation: Appear .15s ease-in-out 1
        & > div
            background: #ffffff
            border-radius: 50px
            padding: 50px
            min-height: 480px
            display: flex
            flex-direction: column
            justify-content: center
            box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2)
            flex-grow: 1
    .label
        span
            background: linear-gradient(180deg, rgba(255,255,255,0) 43%, rgba(255,255,255,255) 46%, rgba(255,255,255,255) 51%, rgba(255,255,255,0) 54%) !important
    .button
        margin-top: 15px
        font-size: 16px
    .breadcrumbs, h2, .paragraph
        padding-left: 27px
    .breadcrumbs
        margin: 9px 0 7px
    .form
        .row:last-child
            margin-bottom: 0
    h2
        line-height: 34px
    h2, .accent-frame
        margin-bottom: 12px
    .form
        margin-top: 30px
    .paragraph
        margin: 0 0 15px
        line-height: 22px
        color: $color-text-light
        .link
            color: $color-text
            &:hover
                color: $color-brand
        &.action
            margin: 0 27px 13px 0
            text-align: right
            .link
                border-bottom-color: #fff
                &:hover
                    border-bottom-color: inherit
        & + .accent-frame
            margin-top: -10px
