.search-fullsize-wrapper
    padding-top: 30px

    @include tablet
        padding-top: 10px

.search .form-block
    background: #2e6d6d url("/images/search/sea.png") no-repeat bottom
    background-size: cover
    border-radius: 26px
    padding: 40px 60px
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 30px

    .checkbox-wrapper
        span
            font-size: 14px
    .value-object
        white-space: nowrap

    @include large-laptop
        grid-template-columns: 1fr 2fr
        padding: 40px

    @include tablet
        grid-template-columns: 1fr
        padding: 26px

    .title
        margin-top: 20px
        max-width: 450px

        @include tablet
            margin-top: 0
            max-width: fit-content
            padding: 0 20px 20px
            grid-row: 2 / span 1

        h1, span
            color: $color-white

        h1
            font-size: 48px
            margin-bottom: 40px

            @include large-laptop
                font-size: 40px
            @include laptop
                font-size: 34px
                margin-bottom: 20px

.search.block .fullsize
    background-color: $color-white
    border-radius: 24px
    padding: 40px
    display: flex
    justify-content: center
    gap: 20px

    @include laptop
        padding: 20px

    .field
        margin-right: 0

    & > .row
        margin-bottom: 0
        display: grid
        grid-template-columns: repeat(2, 1fr)
        gap: 18px

        @include mobile
            grid-template-columns: 1fr

    .button-holder
        margin-top: 10px
        width: 100%
        button
            width: 100%
            height: 44px
            color: $color-white

            &:hover
                background-color: $color-yellow
                border-color: $color-yellow

    .guests-field
        & > label > .input
            padding-right: 6px
