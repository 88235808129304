header
    z-index: 1001
    background: #ffffff
    position: fixed
    width: 100%
    height: 80px
    line-height: 22px
    font-size: 14px
    transition: box-shadow 0.2s
    box-shadow: 0 .5px 0 #ffffff
    &.fixed
        transition: box-shadow 1.2s, height 0.4s ease
        box-shadow: 0 .5px 0 $color-shadow-border
        @include tablet
            height: 80px !important
            overflow: hidden
    a
        display: inline-block
        vertical-align: top

    section
        display: flex
        justify-content: space-between
        margin: 0 auto

    .search-wrapper
        margin-top: 16px
        position: relative

    .agent-menu
        padding: 17px 0
        text-align: right
        white-space: nowrap
        @media (min-width: 1295px)
            min-width: 240px
        .button
            height: 46px
            margin-left: 15px
            &:hover
                border-color: $color-brand
                box-shadow: none
        .agency-search-button
            @media (max-width: 1024px)
                display: none
    .agent-link
        padding: 5px
        height: 46px
        margin-left: 14px
        display: inline-flex
        flex-direction: row
        .icon
            margin: 0 9px
        &:hover
            .icon
                filter: none
        .avatar
            background: url("./imgs/no-avatar.svg") center center no-repeat
            background-size: contain
            margin: 0
            &, & img, & svg
                height: 34px
                width: 34px
                display: inline-block

    .logo-wrapper
        width: 50%
        transform: translateX(68px)
        display: flex
        justify-content: flex-end
        min-width: 157px
        .logo
            width: 137px
            height: 74px
            position: relative
            .image, .underline
                width: 137px
                height: 74px
                background: url("/images/logo/logo.png") no-repeat
                display: block
                background-size: contain
                position: relative
                z-index: 2
            .underline
                height: 130px
                width: 130px
                background: linear-gradient(90deg, #F9A51B 0%, #FECE08 100%)
                position: absolute
                top: -61px
                left: 3px
                z-index: 1
                border-radius: 100px

@include large-laptop
    .with-search
        .notifications
            z-index: 10000 !important
    header.with-search
        height: 160px
        .search-wrapper
            display: block
            position: absolute
            top: 80px
            left: 50%
            transform: translateX(-50%)
            max-width: 100%
            padding: 0 5px
            section
                justify-content: space-around

@include tablet
    header
        .logo-wrapper
            width: 140px
            transform: none
            justify-content: flex-start

@include desktop
    header.with-search, header.landing-header
        .logo-wrapper
            width: 240px
            transform: none
            justify-content: flex-start
