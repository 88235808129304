.blog-lg
    h1
        text-align: center

    .content
        padding: 60px 0
        display: grid
        grid-column-gap: 20px
        grid-row-gap: 60px
        grid-template-columns: repeat(2, 1fr)

        @include tablet
            padding: 40px 0
            grid-template-columns: repeat(1, 1fr)

    .image
        width: 100%
        border-radius: 20px
        height: 400px
        object-fit: cover

    .title
        margin: 32px 0 10px

.article-page
    margin: 0 auto 100px
    max-width: 800px

    .title
        text-align: left
        margin: 32px 0 40px

        @include tablet
            margin-bottom: 24px

    .image
        margin: 20px 0 40px
        width: 100%
        border-radius: 24px

    .text
        p
            margin-bottom: 20px
