.modal.map-modal
    position: relative
    width: 95%
    max-width: 95%
    height: 90vh
    padding: 0
    overflow: hidden
    .shadow
        box-shadow: 0 2px 8px 0 #00000033
    .button.close-button
        z-index: 500
        display: flex
        flex-direction: row
        gap: 8px
        font-size: 13px
        font-weight: 400
        padding: 7px 10px
        margin: 40px 30px 0 0
        .icon
            opacity: 1
            zoom: 0.75
    .marker-popup
        width: 260px
        .inner
            padding: 16px
            .hotel-name
                font-size: 18px
                line-height: 22px
                font-weight: 500
            .rating
                margin: 6px 0 14px
            .price
                .price-from
                    font-size: 12px
                    line-height: 14px
                    font-weight: 600
                    color: #777777
                    margin-bottom: 4px
                .amount
                    font-size: 22px
                    line-height: 27px
                    font-weight: 500
        .button
            width: 260px
            height: 50px
            line-height: 50px
            padding: 0
            border-radius: 0
            font-weight: 500
            font-size: 16px
            text-transform: uppercase
    &.single
        .leaflet-popup-close-button
            display: none
        .rating
            margin-bottom: 0 !important

.map-button-holder
    @include desktop
        margin: 20px 0 15px
    .button
        @include desktop
            height: 40px
        width: 100%
        border-color: $color-gray-dark-border
        &:hover
            border-color: $color-brand
    .map-button-image
        padding-bottom: 10px
        text-align: center
        cursor: pointer
        display: none
        @include desktop
            display: block
    img
        filter: grayscale(1)
        width: 200px
        height: 87px
    &:hover
        img
            filter: none
        .button
            color: $color-brand
            border-color: $color-brand

.leaflet-container
    width: 100%
    height: 100%
    overflow: hidden
.leaflet-control-attribution
    display: none
.leaflet-marker-icon
    opacity: 1
.leaflet-popup-content
    margin: 0
    width: 260px !important
.leaflet-popup-content-wrapper
    padding: 0
    overflow: hidden
.leaflet-popup-tip
    background: #f4be45
.leaflet-popup
    margin-bottom: 45px
.marker-cluster
    background-color: transparent !important
    background-image: url("./imgs/map-group.svg") !important
    background-size: cover !important
    & div
        background: none !important
.leaflet-control
    right: 20px
    top: 110px
    display: flex
    flex-direction: column
    gap: 10px
    border: 0 !important
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out
        width: 40px !important
        height: 40px !important
        line-height: 36px !important
        border: 1px solid #fff
        border-radius: 20px !important
        box-shadow: 0 2px 8px 0 #00000033
        &:hover
            color: $color-brand
            border-color: $color-brand
            background: #fff
