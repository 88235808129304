@font-face
    font-family: 'Inter'
    src: url('/styles/landing/fonts/Inter-Bold.ttf') format('truetype'), url('/styles/landing/fonts/Inter-ExtraBold.ttf') format('truetype')
    font-weight: 700
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Inter'
    src: url('/styles/landing/fonts/Inter-Medium.ttf') format('truetype')
    font-weight: 500
    font-style: normal
    font-display: fallback

@font-face
    font-family: 'Inter'
    src: url('/styles/landing/fonts/Inter-Light.ttf') format('truetype')
    font-weight: 300
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Inter'
    src: url('/styles/landing/fonts/Inter-ExtraLight.ttf') format('truetype')
    font-weight: 200
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Inter'
    src: url('/styles/landing/fonts/Inter-Regular.ttf') format('truetype')
    font-weight: 400
    font-style: normal
    font-display: fallback

@font-face
    font-family: 'Inter'
    src: url('/styles/landing/fonts/Inter-SemiBold.ttf') format('truetype')
    font-weight: 600
    font-style: normal
    font-display: swap
