.breadcrumbs
    color: $color-text-light
    margin-top: 40px
    font-size: 14px
    line-height: 17px
    cursor: default
    position: relative
    display: flex
    flex-direction: row
    justify-content: space-between
    font-weight: 300
    .links
        padding-right: 30px
    a:hover
        color: $color-text
    .back-button
        cursor: pointer
        min-width: 60px
        text-align: right
        span
            margin-right: 4px
        &:hover
            color: $color-text
    @include tablet
        margin-top: 30px

.small-arrow-right,
.small-arrow-left
    border: 1px solid
    border-left: 0
    border-top: 0
    width: 5px
    height: 5px
    display: inline-block
    line-height: 0
    transform: rotate(-45deg)
    position: relative
    top: -2px
    margin: 0 4px

.small-arrow-left
    transform: rotate(135deg)
    margin: 0
