.booking.block
    padding-bottom: 100px
    word-break: break-word
    .room-details
        margin: 20px 0 30px
        width: 100%
        td
            padding-bottom: 10px
            padding-right: 7px
            text-align: left
            font-weight: 400
            width: 45%
            &:first-child
                min-width: 140px
                max-width: 140px
                width: 140px !important
            &:last-child
                padding-right: 0
    .row
        margin-bottom: 20px
        max-width: 540px
    .itinerary-wrapper
        margin: 32px 0
        background-color: $color-background
        border-radius: 20px
        overflow: hidden
        .itinerary-number
            padding: 2px 36px
            display: flex
            align-items: center
            justify-content: space-between
            p
                padding: 10px 0
                line-height: 30px
                @include laptop
                    line-height: 26px
            button
                min-height: 30px
                height: auto
                position: relative
                z-index: 160
                @include tablet
                     padding: 2px 8px
                     min-height: 26px
    .promotion
        background-color: $color-background
        border-radius: 10px
        padding: 8px 14px
        display: flex
        flex-direction: column
        gap: 24px
        b
            font-weight: 600
        p
            margin-top: 4px
    .billet
        min-width: 420px
    .another
        .accent-frame:first-child
            margin-top: 0
    .room-container
        margin-top: 40px
        .textarea
            min-height: 100px !important
    h2+.allotment
        margin-top: 35px
    @include laptop
        .billet-wrapper
            flex-direction: column-reverse
            .billet
                margin: 40px auto 0
    .tabs-wrapper
        margin: -12px -20px 1px
        @include tablet
            margin: 0
    .methods-of-payment
        .line
            margin-bottom: 0
            font-weight: 400
            line-height: 34px !important
            align-items: center
            span
                margin-left: 4px
        span
            color: $color-text-light
        span.balance
            color: $color-green
        img
            max-width: 25px
            max-height: 18px
            margin-left: 12px
            vertical-align: middle
            &:last-child
                margin-left: 15px

    &.external
        section
            margin-top: 80px
            max-width: 800px
            padding-bottom: 160px
        .short-summary
            margin: 20px 0 70px
            font-weight: 300
            font-size: 16px
            div
                margin-bottom: 10px
        h2
            margin-bottom: 20px

    .cancelled-room
        background: #F7F8FC
        padding: 20px
        border-radius: 8px
        position: relative
        cursor: pointer
        &.expanded
            cursor: default
        .icon-big-expand
            position: absolute
            top: 20px
            right: 20px
            cursor: pointer
        h2
            font-size: 20px
            padding-right: 40px
        .status
            margin: 10px 0 0
            &+.allotment
                margin-top: 25px

.room-code
    margin: 4px 0 16px
    color: $color-text-light
    font-weight: 700

.history-status
    margin-top: 80px
    h3, h2
        margin-bottom: 20px
    b
        font-weight: 600
    ol
        li
            list-style: decimal outside
            margin-left: 17px
            margin-bottom: 14px
            line-height: 21px

.offline-booking-cancel
    margin: 16px auto
    text-align: center
    color: $color-text-light
