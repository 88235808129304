.form
    display: flex
    flex-direction: column
    width: 100%
    .row
        display: flex
        width: 100%
        margin-bottom: 20px
    .vertical-label
        cursor: pointer
        width: auto
        padding-left: 14px
        display: flex
        justify-content: center
        flex-direction: column
        margin-top: -3px
    .capitalize
        input, .suggestion span
            text-transform: capitalize

.checkbox
    background: url("./imgs/checkbox-off.svg") no-repeat left center
    padding-left: 27px
    line-height: 22px
    height: 20px
    display: inline-flex
    user-select: none
    &.readonly
        color: $color-text-light
    &:not(.readonly)
        cursor: pointer
    &:hover:not(.readonly)
        color: $color-primary
    &.on
        background-image: url("./imgs/checkbox-on.svg")

.checkbox-wrapper
    display: flex
    align-items: center
    user-select: none

    .icon-check
        display: none

.new-filters
    .checkbox-wrapper
        display: flex
        align-items: center
        gap: 14px
        user-select: none
        font-size: 14px
        span
            line-height: 110%
        &.readonly
            color: $color-text-light
        &:not(.readonly)
            cursor: pointer
        &:hover:not(.readonly)
            color: $color-primary

    .icon-check
        display: inline

    .checkbox
        background-image: none
        width: 18px
        min-width: 18px
        height: 18px
        border: 2px solid $color-gray-dark-border
        border-radius: 3px
        padding-top: 1px
        padding-left: 0
        display: flex
        align-items: center
        justify-content: center
        transition: 0.3s all

        &.on
            border-color: $color-primary
            background-color: $color-primary

.field-children
    margin-left: 12px
    .age
        margin-left: 16px
        text-transform: uppercase
        font-size: 11px
        color: $color-text-light
        line-height: 14px
        font-weight: 600
    .button
        margin-top: 6px
        margin-right: 4px
        font-size: 7px
        padding-left: 0
        padding-right: 0
        width: 44px
        height: 44px
        .icon
            opacity: .7
        &.main
            .icon
                opacity: .95
