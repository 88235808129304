button
    cursor: pointer
    display: inline-block
    min-width: 0
    min-height: 0
    outline: 0
    border: 0
    background: transparent
    font: inherit
    word-break: normal

.button
    display: inline-flex
    height: 54px
    line-height: 20px
    font-size: 14px
    color: $color-black
    border: 1px solid $color-background-gray
    border-radius: 30px
    cursor: pointer
    background: $color-white
    padding: 3px 26px
    justify-content: center
    flex-direction: column
    align-items: center
    transition: 0.3s all

    &.dark-button
        background: $color-background-gray

    &:hover
        background-color: $color-white
        color: #FF9D19
        border-color: #FF9D19

    &.main
        background: $color-brand
        border: 0
        color: #ffffff
        background: linear-gradient(90deg, #FFCD0B 0%, #FBAC19 100%)
        &:hover
            background: #FF9D19
            background: linear-gradient(90deg, #FF9D19 0%, #F9A51B 100%)

    &.gray
        color: #b0b0b0
        &:hover
            color: $color-brand

    &.green
        color: $color-green
        border-color: $color-green
        &:hover
            color: $color-brand
            border-color: $color-brand

    &.transparent
        text-transform: none
        line-height: 30px
        height: 30px
        border: 1px solid #ffffff
        background: transparent
        color: #ffffff
        padding: 0 15px
        font-weight: 400
        &:hover
            color: #FF9D19
            background: #ffffff

    &.round
        border-radius: 300px !important
        padding: 0 !important
        min-width: 26px
        min-height: 26px
        .icon
            vertical-align: middle

    &.small
        height: auto
        line-height: 14px
        font-size: 10px
        padding: 6px 18px
        font-weight: 500

    &.disabled, &.disabled:hover, &.disabled:active
        cursor: default !important
        pointer-events: none
        background: #fafafa
        border-color: #f0f0f0
        color: #888
        &.main
            color: #fff
            background: #d9d9d9

.file-upload
    font-size: 14px
    padding: 0 30px
    input
        display: none
