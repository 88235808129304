.partnership-block
    margin-top: 114px
    display: grid
    grid-template-columns: 0.9fr 1.1fr
    align-items: stretch
    gap: 100px

    @include large-laptop
        gap: 16px

    @include laptop
        grid-template-columns: 1fr

    @include tablet
        margin-top: 56px
        grid-template-columns: 1fr

    .text-wrapper
        display: flex
        flex-direction: column

    .icon-plane
        margin: 0 20px

    .title
        text-align: left
        margin-bottom: 32px

    .text
        margin-bottom: 38px

    .links-group
        display: flex
        gap: 24px
        align-items: baseline

        @include laptop
            margin-bottom: 32px

        @include tablet
            .dark-link
                display: none

    .learn-more-button
        margin-bottom: 16px

        @include tablet
            width: 100%
            button
                width: 100%

    .platform-link
        margin-top: auto
        margin-bottom: 72px

        @include laptop
            width: 90%
            margin: auto

        @include tablet
            width: 100%

    .info-plate
        width: 100%
        display: flex
        align-items: center
        justify-content: space-between
        padding: 14px 20px
        border-radius: 20px
        background-color: $color-background-gray

        p
            font-size: 20px
            font-weight: 500

        .plate-image-wrapper
            display: flex
            align-items: center
            gap: 24px

        img
            max-width: 140px
            max-height: 90px
            border-radius: 20px

        .arrow-wrapper
            margin-left: 20px
            width: 40px
            height: 60px
            border-radius: 30px
            background-color: $color-white

        .circle-arrow
            margin: auto
            animation: moveDownAndUp 3s linear infinite

    .application-image
        max-width: 100%
        object-fit: contain

        @include laptop
            width: 85%
            margin: auto

        @include tablet
            width: 100%

.platform-block
    .block-heading
        text-align: center

        h2
            margin-bottom: 28px

        p
            margin-bottom: 60px
            @include tablet
                margin-bottom: 32px

    .swiper-cards
        @include tablet
            .slider-track
                flex-direction: column
                width: 100%

            .circle-arrow
                display: none

        .card-heading
            font-size: 24px
            font-weight: 500
            line-height: 140%

            @include tablet
                font-size: 20px

        .card
            height: 320px
            border-radius: 24px
            padding: 50px 40px
            width: 380px
            background-color: $color-background-gray

            &:nth-child(2)
                width: 500px
                background-color: $color-background-brown
                background-image: url("/images/landing/about-us/pattern3.svg")
                background-repeat: no-repeat
                background-position: 10% 160%

                @include tablet
                    width: 100%

            @include tablet
                width: 100%
                height: fit-content
                padding: 32px

        .text
            font-size: 18px
            font-weight: 400
            margin: 28px 0 16px

            @include tablet
                margin-bottom: 20px

        .discover-card
            width: 214px
            border-radius: 24px
            padding: 18px
            display: flex
            flex-direction: column
            justify-content: space-between
            position: relative
            background: url("/images/landing/product/beach-background.png")
            background-repeat: no-repeat
            background-size: cover

            @include tablet
                width: 100%

            .icon-arrow
                transform: rotate(-135deg)
            .circle-arrow
                margin-left: auto
                position: static
                transform: none

                @include tablet
                    display: flex

            .heading
                background-color: $color-white
                border-radius: 14px
                font-size: 20px
                font-weight: 500
                padding: 18px 14px

                @include large-laptop
                    width: fit-content
                    margin: 8px
                    padding: 10px 14px
                    font-size: 16px

@property --revenue
    syntax: '<integer>'
    initial-value: 0
    inherits: false

@property --bookings
    syntax: '<integer>'
    initial-value: 0
    inherits: false

@property --partners
    syntax: '<integer>'
    initial-value: 0
    inherits: false

.opinion-block
    h2
        text-align: center
        margin-bottom: 60px
        @include tablet
            margin-bottom: 32px

    .swiper-cards
        .card-heading
            font-size: 24px
            line-height: 130%
            font-weight: 500

            @include tablet
                font-size: 20px

        .card
            height: 400px
            border-radius: 24px
            padding: 50px 40px
            width: 380px
            display: flex
            flex-direction: column
            background-color: $color-background-gray

            &:nth-child(1), &:nth-child(4)
                background-color: $color-background-brown
                background-image: url("/images/landing/product/pattern1.svg")
                background-repeat: no-repeat
                background-position: 115% 115%

            &:nth-child(1)
                width: 550px
                @include tablet
                    width: 430px

            @include tablet
                padding: 32px

        .text
            font-size: 16px
            margin: 4px 0

        h3
            font-weight: 600

        .small-text
            font-size: 14px

            &:first-child
                margin-bottom: 24px

            &:last-child
                margin-top: 24px

        .discover-card
            width: 285px
            border-radius: 24px
            padding: 18px
            display: flex
            flex-direction: column
            justify-content: space-between
            position: relative
            background: url("/images/landing/product/sea-building-background.png")
            background-repeat: no-repeat
            background-size: cover

            .icon-arrow
                transform: rotate(-135deg)
            .circle-arrow
                margin-left: auto
                position: static
                transform: none

                @include tablet
                    display: flex

            .heading
                background-color: $color-white
                border-radius: 14px
                font-size: 20px
                font-weight: 500
                padding: 18px 14px

                @include large-laptop
                    width: fit-content
                    margin: 8px
                    padding: 10px 14px
                    font-size: 16px

        .counters-wrapper
            height: 100%
            display: flex
            flex-direction: column
            justify-content: space-between

            h3
                font-size: 40px

                @include tablet
                    font-size: 36px

        .revenue-number
            transition: --revenue 5s
            counter-reset: revenue var(--revenue)

            &::after
                content: counter(revenue)

        .bookings-number
            transition: --bookings 7s
            counter-reset: bookings var(--bookings)

            &::after
                content: counter(bookings)

        .partners-number
            transition: --partners 5s
            counter-reset: partners var(--partners)

            &::after
                content: counter(partners)

.tailor-made-block
    background-color: $color-background-brown
    border-radius: 24px
    display: flex
    padding: 0 100px !important
    background-image: url('../images/landing/product/tailor-made-background.png')
    background-position: 50%
    background-repeat: no-repeat
    background-size: contain
    justify-content: center
    text-align: center

    @include laptop
        background-image: url('../images/landing/product/tailor-made-bg-mobile.png')
        padding: 0 32px !important

    @include mobile
        background-size: cover

    .content
        padding: 100px 0
        max-width: 600px

        @include laptop
            padding: 120px 0 200px

    .text
        margin: 32px 0 40px

.questions-block
    h2
        margin-bottom: 60px

    .content
        display: grid
        grid-template-columns: 2fr 1fr
        grid-gap: 16px

        @include large-laptop
            grid-template-columns: 1fr

    .content[data-auth=true]
        grid-template-columns: 1fr

    .questions-list
        display: flex
        flex-direction: column
        gap: 16px

    .question-card
        cursor: pointer
        background-color: $color-background-gray
        border-radius: 24px
        padding: 34px 40px
        transition: 0.3s all

        .text
            pointer-events: none
            height: 0
            opacity: 0
            margin: 0
            transition: 0.1s opacity, 4s height, 0.6s margin

    .question
        display: flex
        justify-content: space-between
        align-items: center
        gap: 16px

        p
            font-size: 24px
            font-weight: 500
            line-height: 130%

    .icon-wrapper
        cursor: pointer
        position: relative
        margin-left: auto
        margin-bottom: 6px
        z-index: 10
        min-width: 48px
        height: 48px
        border-radius: 50%
        background-color: $color-white
        display: flex
        justify-content: center
        align-items: center
        transition: 0.5s opacity

        .icon-plus
            position: absolute
            transform: rotate(90deg)
            transition: 0.3s all
            top: 15px
            left: 15px

    .question-card[data-opened=true]
        background-color: $color-background-brown
        .icon-plus
            transform: none

        .text
            pointer-events: auto
            height: auto
            opacity: 1
            margin-top: 40px
            transition: 1s opacity, 1s height, 0.6s margin

    .image-block
        height: 100%
        border-radius: 24px
        padding: 40px
        background-image: url('../images/landing/product/questions-background.png')
        background-position: 50% 0
        background-repeat: no-repeat
        background-size: cover

        @include large-laptop
            padding: 20px

        @include laptop
            padding: 40px
            grid-row: 1 / span 1

        .image-block-content
            background-color: $color-white
            border-radius: 24px
            padding: 44px 52px
            text-align: center

            @include large-laptop
                padding: 24px 16px

            @include laptop
                padding: 40px

            h3
                margin-bottom: 32px

@keyframes CardOpen
    0%
        opacity: 0
    70%
        opacity: 0
    100%
        opacity: 1

@keyframes HiddenDataOpen
    0%
        width: 0
        height: 0
        opacity: 0
    50%
        width: 0
        height: 0
        opacity: 0
    70%
        opacity: 0
    100%
        width: auto
        height: auto
        opacity: 1

@keyframes ImageAnimation
    0%
        transform: translate(0, 300px)
    40%
        transform: translate(0, 300px)
    100%
        transform: translate(0, 0)

@keyframes LoadingLine
    0%
        width: 0
    100%
        width: 100%

.services-block
    display: flex
    gap: 16px

    .card
        cursor: pointer
        width: 160px
        height: 650px
        background-color: $color-background-brown
        background-repeat: no-repeat
        border-radius: 40px
        padding: 24px 50px
        position: relative
        transition: 0.5s flex, 1s background-color, 0.5s width
        display: grid
        grid-template-columns: 2fr 1fr
        gap: 30px
        align-items: center

        @include large-laptop
            width: 120px
            padding: 32px
            gap: 16px

        @include laptop
            grid-template-columns: 1fr
            width: 80px
            height: 850px

        @include tablet
            height: 700px

        @include mobile
            width: 60px
            height: 650px
            padding: 8px

        &::after
            content: ''
            position: absolute
            width: 100%
            height: 3px
            background-color: $color-background-gray
            bottom: -30px

        &::before
            content: ''
            position: absolute
            width: 0
            height: 3px
            bottom: -30px
            z-index: 10
            background-color: $color-primary
            transition: none

        &:first-child
            background-image: url('/images/landing/product/pattern2.svg')
            background-position: 30% 10%

        &:nth-child(2)
            background-image: url('/images/landing/product/pattern3.svg')
            background-position: 55% 10%

        &:last-child
            background-image: url('/images/landing/product/pattern4.svg')
            background-position: 45% 10%

        .hidden-block, .image-wrapper, .image, img
            width: 0
            height: 0
            display: none

        .text
            margin: 32px 0

            @include mobile
                margin: 16px 0

        h2
            @include mobile
                font-size: 24px

    .closed-card
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: flex
        justify-content: space-between
        align-items: end
        flex-direction: column
        padding: 24px

        @include laptop
            padding: 20px
        @include mobile
            padding: 8px 8px 20px 8px
            align-items: center

        .icon-wrapper
            position: relative
            height: 40px
            width: 40px
            background-color: $color-white
            border-radius: 50%
            display: flex
            justify-content: center
            align-items: center

        .icon-minus
            height: 2px

        .icon-plus
            position: absolute
            transform: rotate(90deg)
            top: 18px
            left: 11px

        .subtitle
            width: 100%
            margin-top: 10px
            font-size: 24px
            font-weight: 500
            writing-mode: vertical-rl

            @include mobile
                font-size: 18px
                margin-right: 16px

    .card[data-active=true]
        flex: 1
        width: fit-content
        background-color: $color-background-gray
        background-image: none
        position: relative

        @include mobile
            padding: 20px

        &::before
            width: 100%
            animation: LoadingLine 10s

        .image-wrapper
            height: 100%
            overflow: hidden
            margin-bottom: -46px
            @include laptop
                margin-bottom: -63px
            @include mobile
                margin-bottom: -40px
        .image
            margin-top: 76px
            width: 100%
            overflow: hidden

            @include laptop
                margin-top: 0
                display: flex

            img
                max-width: 100%
                margin-top: 20px
                overflow: hidden
                object-fit: cover
                object-position: top
                transform: translate(0, 300px)

                @include laptop
                    max-width: 50%
                    margin: auto
                @include mobile
                    max-width: 70%

        .image-wrapper, .image, img
            animation: CardOpen 0.7s
            width: auto
            height: auto
            display: block

        .hidden-block
            animation: HiddenDataOpen 0.7s
            width: auto
            height: auto
            display: block

        .image-wrapper
            height: 100%
            img
                transform: translate(0, 0)
                animation: ImageAnimation 1s

        .closed-card
            display: none

    .card[data-stopped-interval=true]
        &::before
            animation: none
            transition: none
