.cabinet-header
    section
        max-width: 886px
        padding: 75px 10px 25px
        display: flex
        justify-content: space-between
        position: relative
        @include tablet
            padding-top: 105px
        .agent
            min-height: 180px
            display: flex
            color: $color-text-light
            .photo
                width: 100px
                height: 100px
                position: relative
                border-radius: 80px
                margin: 0 30px 0 15px
                overflow: hidden
                .no-avatar, img
                    width: 100%
                    height: 100%
                .no-avatar
                    background: url("./imgs/no-avatar.svg") center center no-repeat
                    background-size: cover
            .data
                display: flex
                flex-direction: column
                justify-content: flex-start
                h1
                    font-size: 24px
                    line-height: 28px
                    font-weight: 500
                    color: $color-text
                .company
                    .name
                        font-weight: 400
                        font-size: 15px
                .status
                    width: 14px
                    height: 14px
                    display: inline-block
                    margin: 0 5px 0 12px
                    vertical-align: bottom
                    border-radius: 25px
                    &.PendingVerification
                        background: lightyellow
                        border: 3px solid yellow
                    &.FullAccess
                        background: $color-green
                        border: 3px solid #bbecd2
                    &.DeclinedVerification
                        background: red
                        border: 3px solid lightcoral
                    &.ReadOnly
                        background: blue
                        border: 3px solid skyblue
                .positive
                    color: $color-green
                    font-weight: 500
                .section
                    margin-top: 15px
                    span
                        font-weight: 600
                        color: $color-text
                        &.positive
                            color: $color-green
                            font-weight: 500
    .logout-wrapper
        white-space: nowrap
        .button
            display: block
            height: 44px
            line-height: 40px
            padding: 0 23px 0 18px
            font-weight: 400
            .icon
                margin: -3px 6px 0 0
                vertical-align: middle
                filter: grayscale(1)
            &:hover
                .icon
                    filter: none
        @include tablet
            position: absolute
            top: 20px
            right: 10px
    .cabinet-main-navigation
        box-shadow: 0 .5px 0 $color-shadow-border
        section
            padding: 0 7px
            display: flex
            justify-content: flex-start
        a
            font-size: 13px
            line-height: 16px
            text-align: center
            color: $color-text-light
            margin: 0 10px
            padding: 19px 14px
            position: relative
            align-items: center
            display: flex
            &:hover
                color: $color-text
            &.active
                cursor: default
                color: $color-brand
                &:after
                    content: ' '
                    height: 4px
                    width: 100%
                    background: linear-gradient(90deg, #F9A51B 0%, #FECE08 100%)
                    border-radius: 25px
                    position: absolute
                    bottom: 0
                    left: 0
