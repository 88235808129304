.search.block .short
    border: 1px solid $color-border
    border-radius: 50px
    width: auto
    transition: border-color 0.3s ease-in-out
    position: relative
    flex-direction: row
    justify-content: center
    max-width: 740px
    background: #fff
    animation: Appear .15s ease-in-out 1
    & > .field
        position: static
        min-width: 0
        margin-right: 0
        padding: 0 15px 0 15px
        flex-grow: 0
        text-align: center
        .dropdown
            text-align: left
        &:nth-child(1)
            .value-object
                text-overflow: ellipsis
        &:nth-child(2)
            flex-grow: 1
        &:nth-child(3)
            min-width: 110px
        &:nth-child(4)
            padding-right: 12px
            min-width: 46px
        &+.field
            background: url("./imgs/separator.svg") left center no-repeat
        & > label
            .input
                padding: 0
                margin-right: 0
                border: 0
                height: 46px
                line-height: 44px
                &:first-child
                    border-top-left-radius: 25px
                    border-bottom-left-radius: 25px
    .error:not(.focus)
        & > label
            .placeholder
                color: $color-red !important
    .button-holder
        max-width: 47px
        min-width: 47px
        padding: 0
        background: none !important
    .main.button
        width: 34px
        height: 34px
        padding: 0
        margin: 6px 8px 0 6px
    .overall
        position: relative
    .overall:not(.focus)
        max-width: 240px
    .overall.focus
        max-width: none
        position: absolute
        top: -1px
        left: -1px
        right: -1px
        padding-left: 15px
        background: #fff
        z-index: 30
        border-radius: 42px
        border: 1px solid $color-primary
    .flags
        text-transform: capitalize
        .flag
            margin-right: 4px
        .element+.element
            margin-left: 12px
    .date.dropdown
        left: 50%
        transform: translateX(-50%)
    @media (max-width: 670px)
        .overall:not(.focus)
            max-width: 170px

    @media (max-width: 680px)
        .overall:not(.focus)
            max-width: 170px

    @media (max-width: 580px)
        font-size: 12px !important
        input, .input, .placeholder, .suggestion
            font-size: 12px !important
            touch-action: none
        & > .field
            &:nth-child(1)
                flex-grow: 0
                & > label
                    .icon-wrap
                        display: block
            &:nth-child(2)
                flex-grow: 1
                min-width: 90px
            &:nth-child(3)
                min-width: 70px
            &:nth-child(4)
                min-width: 40px
            & > label
                .flags
                    .flag
                        margin-right: 0
                    .element
                        font-size: 0
    @media (max-width: 530px)
        & > .field
            padding: 0 10px
            & > label
                .icon-wrap
                    display: none

    @media (max-width: 1120px)
        .hide-mobile
            display: none

