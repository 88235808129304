.new-swiper-lg
    position: relative
    user-select: none

    .slider-list
        width: 100%
        overflow-x: auto

        &::-webkit-scrollbar
            width: 0
            height: 0

        &::-webkit-scrollbar-thumb
            background: transparent

        &::-webkit-scrollbar-track
            background: transparent

    .slider-track
        touch-action: pan-y
        width: max-content
        display: flex
        gap: 16px

    .circle-arrow
        cursor: pointer
        position: absolute
        top: 50%
        z-index: 1
        transform: translate(0, -50%)
        width: 40px
        height: 40px
        opacity: 1
        transition: 0.3s all

    .prev-arrow
        left: 4px

        .icon-arrow
            transform: rotate(90deg)

    .next-arrow
        right: 4px

        .icon-arrow
            transform: rotate(-90deg)

    .hide-arrow
        opacity: 0
