.lg-header
    position: relative
    height: auto
    margin-bottom: 72px
    @include laptop
        margin-bottom: 48px

    @include tablet
        margin-bottom: 30px

    nav
        z-index: 100
        position: relative
        margin: 32px 0
        padding: 16px 32px
        border-radius: 24px
        background-color: $color-background-gray
        display: flex
        justify-content: space-between
        align-items: center
        @include tablet
            padding: 12px 16px

    .logo
        position: absolute
        left: 50%
        transform: translateX(-50%)
        top: 0
        width: 17%
        max-width: 205px

        @include laptop
            width: 150px

        @include tablet
                width: 116px

    .links-group
        display: flex
        gap: 8px

        @include laptop
            display: none

    .link-wrapper
        display: flex
        align-items: center
        gap: 8px

        span
            transition: opacity 0.3s ease-in-out
            opacity: 0
        &:hover
            span
                opacity: 1

    .link-wrapper[data-active-link=true]
        .dark-link
            border-bottom: 1px solid $color-black

    .buttons-group
        display: flex
        gap: 20px
        @include tablet
            display: none

    .icon-cross, .icon-burger-menu
        cursor: pointer

    .hide-element
        display: none

    .mobile-menu
        position: absolute
        top: -32px
        height: 0
        opacity: 0
        padding: 84px 32px 16px
        width: 100%
        background-color: $color-background-gray
        border-radius: 24px
        transition: 0.5s all
        display: flex
        flex-direction: column
        z-index: 30

        .mobile-buttons-group
            margin-top: 40px
            width: 100%
            display: flex
            gap: 16px

            @include mobile
                flex-direction: column

            button
                flex: 1

        .mobile-links
            width: 100%
            .dark-link
                font-size: 34px
                text-transform: capitalize
                line-height: 180%
                @include tablet
                    font-size: 24px

            .link-wrapper
                span
                    display: none

            .link-wrapper[data-active-link=true]
                .dark-link
                     border-bottom-color: transparent
                span
                    margin-right: 8px
                    display: block
                    opacity: 1

    .mobile-menu-open
        @include laptop
            top: 0
            height: 95dvh
            opacity: 1

    .join-us-link
        display: none

        @include tablet
            display: block

    .initialized-buttons
        display: flex
        align-items: center
        gap: 16px

    .agent-link
        background-color: $color-white
        padding: 5px
        height: 46px
        margin-left: 14px
        display: flex
        align-items: center
        border: 1px solid $color-gray-border
        border-radius: 30px
        transition: 0.3s all

        &:hover
            border-color: $color-primary

        @include tablet
            height: auto
            padding: 2px

        .icon-burger-menu
            background-size: 15px

            @include tablet
                background-size: 10px

        .icon-avatar, &svg, &rect
            @include tablet
                margin: 0
                height: 22px
                width: 22px

