.payment.block
    padding-bottom: 100px
    section
        max-width: 520px
        padding-top: 100px
    .icon.icon-info
        position: relative
        margin-right: -12px
        top: -2px
        right: -16px
        cursor: default
    h2
        font-weight: 300
    h2+form
        .form
            margin-top: 20px
    .checkbox
        .icon.icon-info
            right: -7px
    .accent-frame
        margin-bottom: 50px
        .data.only
            color: $color-text
    .form
        .field
            position: relative
            &.force-incorrect
                .input.valid
                    &:after
                        display: none
            &.valid
                &:after
                    content: " "
                    background: url("./imgs/check-round.svg") left top no-repeat
                    position: absolute
                    top: 4px
                    right: -5px
                    display: block
                    width: 18px
                    height: 18px
            .after-icon-wrap
                img
                    height: 20px
                    margin: 12px 0 -6px
    .error-page
        .text
            padding-bottom: 60px
    .breadcrumbs
        margin-top: -20px
    .button
        margin-top: 12px
        font-size: 16px
        font-weight: 500
        width: 100%
        flex-direction: row
        justify-content: center
        .icon
            margin-right: 12px
