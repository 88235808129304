@keyframes NotificationAppear
    0%
        transform: translateX(100%)
    100%
        transform: translateX(0)

.notifications
    width: 100%
    margin-top: 40px
    &.fixed
        position: fixed
        top: 90px
        z-index: 6000
        margin-top: 0
        animation: NotificationAppear .4s ease 1
        .wrapper
            display: block
            position: sticky
            top: 20px
            left: 100%
            max-width: 430px
            @media (max-width: 600px)
                max-width: 100%
        .list
            position: absolute
            padding: 10px 20px
            width: 100%
        .item
            padding: 14px 0 0
            .content
                padding-bottom: 14px
            .holder
                overflow-y: auto
                max-height: 150px
                padding-right: 14px
            .text
                max-width: 240px
        .with-photo
            margin-bottom: 0
            .photo, img
                img
                    margin-left: 20px
                    width: 70px
                    height: 70px
        @media print
            display: none
    .item
        background: #FFFFFF
        border-radius: 25px
        padding: 14px
        margin-bottom: 20px
        position: relative
        overflow: hidden
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2)
        transform: translate3d(0, 0, 0)
        .content
            display: flex
        .text
            display: flex
            flex-direction: column
            justify-content: center
            padding-left: 22px
            line-height: 18px
            min-height: 58px
            word-wrap: break-word
            div
                margin-bottom: 10px
        h2
            font-size: 14px
            line-height: 18px
            padding: 0 0 7px
            margin: 0
            font-weight: 600
        .style
            width: 90px
            min-width: 90px
            box-shadow: .5px 0 0 $color-shadow-border
            background-position: center center
            background-repeat: no-repeat
        .close-button
            top: 23px

    .time
        color: $color-text-light
        font-size: 12px
        margin-bottom: 10px

    a[href]
        color: $color-blue

    .with-photo
        display: flex
        margin-bottom: -14px
        .text
            padding-left: 0
            padding-bottom: 14px
        .photo
            margin-right: 22px
        .photo, img
            max-width: 90px
            max-height: 90px
            img
                width: 90px
                height: 90px
                object-fit: cover
                border-radius: 15px
        .style
            margin-right: 22px
            margin-bottom: 14px

    .progress-timer
        width: 100%
        height: 3px
        margin-top: -3px
        border-radius: 2px

    .bar
        height: 100%
        border-radius: 3px
        background: #aaa
        animation: progress 25s linear
        width: 200%
        margin-left: -100%
    .double
        .bar
            animation: progress 30s linear

    .warning
        & .style, &.style
            background-image: url("./icons/notification/warning.svg")
        .progress-timer .bar
            background: $color-brand

    .information
        & .style, &.style
            background-image: url("./icons/notification/information.svg")
        .progress-timer .bar
            background: #3E90F2

    .success
        & .style, &.style
            background-image: url("./icons/notification/success.svg")
        .progress-timer .bar
            background: #5CC873

@keyframes progress
    from
        transform: scaleX(1)
    to
        transform: scaleX(0)
