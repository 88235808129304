.lg
    font-size: 16px
    line-height: 140%

    .text
        font-size: 20px
        line-height: 140%

    h1
        font-size: 52px
        font-weight: 500
        line-height: 130%

        @include laptop
            font-size: 48px
        @include mobile
            font-size: 32px

    h2
        font-size: 44px
        font-weight: 500
        line-height: 120%

        @include laptop
            font-size: 36px

        @include mobile
            font-size: 32px

        span
            color: $color-primary

    h3
        font-size: 32px
        font-weight: 500
        line-height: 120%

        @include mobile
            font-size: 24px

    .hide-desktop
        display: none

    @include mobile
        .hide-desktop
            display: block
        .hide-mobile
            display: none

    .hide-laptop-desktop
        display: none
    .hide-laptop
        display: block

    @include laptop
        .hide-laptop-desktop
            display: block
        .hide-laptop
            display: none
