.management.block
    padding-bottom: 150px
    @include except-phone
        .sticky-header-before
            height: 110px
        .sticky-header
            position: sticky
            top: 25px
            z-index: 1002
            white-space: nowrap
            height: 0
            h2
                overflow: visible
                max-width: 35vw
    h2
        font-size: 24px
        line-height: 30px
        margin: 25px 0 45px
        font-weight: 300

    .controls
        .form
            @include tablet
                display: flex
                flex-direction: column-reverse
                justify-content: flex-end

    .content
        min-height: 370px

    .gray
        color: $color-text-light

    .payment-amount
        font-size: 90%

    .the-table
        tr
            $cols: (1: 130px, 2: 170px, 3: 110px, 4: 130px, 5: 90px, 6: 80px, 7: 80px, 8: 110px)
            @each $id, $col in $cols
                td:nth-child(#{$id}):not([colspan])
                    min-width: $col !important
                    max-width: $col !important

    .agency
        .the-table
            .parent-row
                $cols: (1: 220px, 2: 190px, 3: 190px, 4: 180px, 5: 140px, 6: 180px, 7: 100px)
                @each $id, $col in $cols
                    td:nth-child(#{$id}):not([colspan])
                        min-width: $col !important
                        max-width: $col !important
            tr
                $cols: (1: 160px, 2: 160px, 3: 200px, 4: 100px, 5: 100px, 6: 110px, 7: 80px, 8: 80px, 9: 80px)
                @each $id, $col in $cols
                    td:nth-child(#{$id}):not([colspan])
                        min-width: $col !important
                        max-width: $col !important
                .children-row
                    width: 100%
                    tr
                        border: 0 !important
                    td
                        padding-right: 15px
                        &:first-child
                            padding-left: 20px
                    $cols: (1: 200px, 2: 200px, 3: 160px, 4: 120px, 5: 95px, 6: 85px, 7: 80px, 8: 80px)
                    @each $id, $col in $cols
                        td:nth-child(#{$id})
                            min-width: $col !important
                            max-width: $col !important

        .agent-filter
            display: flex
            align-items: flex-start
            .checkbox-wrapper
                margin-top: 52px
                margin-left: 15px
                cursor: pointer
                @include tablet
                    margin: 0 0 30px 10px

    &.payments-history
        .table
            overflow: visible
            td
                word-break: break-all
            .controls
                .left
                    margin-top: 30px
                    flex-grow: 1
                    justify-content: end
                    .form
                        min-width: 270px
                        width: auto
                        flex-direction: row
                        gap: 17px
                        padding: 0
                        margin-bottom: 20px
                        .button
                            padding-top: 0
                            padding-bottom: 0
                            height: 48px
                        @include desktop
                            .wide
                                width: 270px
                            .button
                                margin-top: 11px
                        @include laptop
                            flex-direction: column-reverse
                        .row
                            margin-bottom: 0
        .the-table
            tr
                $cols: (1: 90px, 2: 130px, 3: 100px, 4: 200px, 5: 80px, 6: 80px, 7: 80px)
                @each $id, $col in $cols
                    td:nth-child(#{$id})
                        min-width: $col !important
                        max-width: $col !important
