.copier-wrapper
    display: flex
    flex-direction: row
    align-items: end

    .copier
        cursor: pointer
        padding: 8px
        margin: -6px 0
        position: relative
        z-index: 1
