.agency-search
    .form-title
        margin-top: 8px
        font-size: 18px
        font-weight: 500
    .fullsize
        padding: 0 !important
        margin-bottom: 40px
        & > .destination-row
            display: grid
            grid-template-columns: 2fr 1fr
            gap: 18px
            @include tablet
                grid-template-columns: 1fr
            .dates-input
                width: 100%
        & > .row
            margin-bottom: 0
            grid-template-columns: repeat(3, 1fr) !important
            gap: 18px
            @include tablet
                grid-template-columns: 1fr !important
    .date
        @include except-phone
            right: 0 !important
            left: inherit !important
            transform: inherit !important
