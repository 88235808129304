@keyframes Appear
    0%
        opacity: 0
    100%
        opacity: 1

.search-results.block.first-step
    @include desktop
        @media (min-height: 750px)
            .heading-section
                position: fixed
                top: 80px
                left: 0
                right: 0
                display: flex
                flex-direction: column
                padding-right: 0
                background: white
                z-index: 10
                @include large-laptop
                    top: 160px
            .heading-section[data-is-agency-search=true]
                position: static
            .head .title h2 div
                display: inline-block
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                margin-right: 8px
                max-width: 90%
            .two-column-wrapper
                .column
                    height: 100%
                    overflow: hidden auto
                    padding: 4px 25px 4px 1px
                    &.left
                        position: fixed
                        height: calc(100vh - 224px)
                        top: 224px
                        overscroll-behavior-y: none
                        scrollbar-color: #ffffff #ffffff
                        scrollbar-width: thin
                        transition: scrollbar-color .3s
                        &:hover
                            scrollbar-color: $color-brand #ffffff
                        @include large-laptop
                            top: 276px
                        &.left[data-is-agency-search=true]
                            position: static
                            height: auto
                    &.right
                        padding-left: 266px
                        padding-top: 142px
                        @include large-laptop
                            padding-top: 190px
                    &.right[data-is-agency-search=true]
                        padding: 0
                        overflow: visible
            &.with-itn
                .notification-wrapper
                    position: fixed
                    top: 80px
                    left: 0
                    right: 0
                    z-index: 3
                .heading-section
                    top: 122px
                    padding-top: 8px
                    .upper-part
                        height: 48px
                .two-column-wrapper
                    .column
                        &.left
                            height: calc(100vh - 254px)
                            top: 244px
                        &.right
                            padding-top: 182px
                        &.right[data-is-agency-search=true]
                            padding: 0
    .request
        color: $color-text-light !important
.search-results.block
    animation: Appear .42s ease-in-out 1
    &.with-padding
        padding-bottom: 100px
    @include laptop
        padding-top: 80px
    .notification-wrapper
        .custom-search-notification
            padding: 0
    .head
        .request
            span
                margin: 0 3px
                &:first-child
                    margin-left: 0
        .title
            h1
                font-weight: 500
                margin: 3px 0 16px
                font-size: 24px
            h2
                margin: 5px 0 10px
                div
                    display: inline
                span
                    color: $color-text-light
                    font-size: 18px
                    vertical-align: top
            .stars
                margin: -10px 0 10px
        .agency-search-title
            display: flex
            align-items: center
            gap: 30px
            font-size: 14px

    .account-balance
        display: flex
        align-items: center
        border: 1px solid $color-gray-border
        border-radius: 40px
        padding: 0 16px 0 8px
        .icon-information
            scale: 0.65
        .price
            margin-left: 4px
            font-weight: 500

    .preferred
        background-color: $color-background-yellow !important
        .plate
            position: absolute
            top: 5px
            left: -11px
            padding: 4px 10px
            border-radius: 4px 4px 4px 0
            background-color: $color-brand
            color: #fff
            font-weight: 600
            text-transform: uppercase
            &.no-photo
                position: relative
                display: inline-block
                border-radius: 4px
                left: 0
                top: -3px
                &:after
                    content: none
            &:after
                content: ''
                position: absolute
                top: 24px
                left: 0
                border: 6px solid transparent
                border-top: 6px solid $color-shadow-border
                border-right: 5px solid $color-shadow-border
    .contract
        .summary
            margin-bottom: 8px
            background: #F4F4F6
            border-radius: 12px
            display: flex
            padding: 15px 17px
            cursor: pointer
            @include tablet
                flex-direction: column
            .photo
                margin-right: 17px
                width: 210px
                min-width: 210px
                height: 130px
                overflow: hidden
                border-radius: 9px
                display: flex
                justify-content: center
                flex-basis: 196px
                cursor: pointer
                background-color: #fff
                background-size: cover
                background-position: center center
                @include tablet
                    width: 100%
                    flex-basis: 250px
                    margin-bottom: 20px
            .upper
                padding-bottom: 15px
            .bottom
                display: flex
                justify-content: space-between
                align-items: flex-end
        .detail
            flex-grow: 1
            display: flex
            flex-direction: column
            justify-content: space-between
            min-height: 110px
            @include tablet
                min-height: 0
            .name
                h2
                    font-size: 18px
                    line-height: 22px
                    font-weight: 600
                    span
                        color: $color-text
                        margin-right: 4px
            .price
                color: $color-text-light
                font-size: 14px
                line-height: 16px
                font-weight: 500
                .text-price
                    font-weight: 500
                    font-size: 22px
                    line-height: 26px
                    color: $color-text
                .text-markup
                    margin-top: 4px
                    color: $color-text
                    font-weight: 400
            .stars
                display: inline-block
                white-space: nowrap
                position: relative
                top: 1px
            .text
                color: $color-text-light
                font-size: 12px
                line-height: 18px
            .address
                display: inline-block
                margin-top: 4px
                font-weight: 400
                font-size: 13px
                line-height: 16px
                color: #555
            .availability
                cursor: pointer
                height: 32px
                font-size: 13px
                line-height: 16px
                font-weight: 500
                padding: 8px 16px
                &:hover
                    color: $color-brand
    .rooms
        margin-bottom: 50px
        .price
            font-size: 18px
            line-height: 22px
            .note
                font-size: 12px
    .stars
        display: inline-block
        i
            width: 20px
            height: 18px
            margin-right: 2px
    .gallery
        margin: 20px 0 10px
    .another
        padding-right: 40px
        @include laptop
            padding-right: 0
        h2
            margin: 30px 0 22px
            &:first-child
                margin-top: 10px
    .finish
        margin: 60px 0 30px
        font-size: 13px
        font-weight: 300
        text-align: center
        color: $color-text-light
    .nothing-found
        padding: 40px 0 0 20px
        &.empty-page
            @media (max-height: 749.5px)
                margin-top: 80px
            justify-content: center
            display: flex
            flex-direction: column
            padding: 0 0 150px
            gap: 80px
            section
                padding-left: 0
                padding-right: 0
            @include desktop
                margin-left: -265px
        .text
            font-size: 14px
            line-height: 22px
            .button
                margin: 0 0 50px
            h2
                margin-bottom: 30px
            h3
                margin-bottom: 10px
    .checkin-time
        margin-top: 10px
        font-weight: 400
        font-size: 14px
    .details-short
        padding: 20px 40px
        border-radius: 25px
        background: #F6F3EB
        margin: 15px 0
        width: auto
        cursor: pointer
        position: relative
        display: flex
        .head
            flex-grow: 1
        .side
            display: flex
            flex-direction: column
            gap: 8px
        @include tablet
            margin: 30px 0
            padding-left: 20px
            padding-right: 20px
            h1
                font-size: 24px !important
                line-height: 30px !important
        @include mobile
            flex-direction: column
            align-items: center
            gap: 32px
            .side
                width: 220px
                justify-content: space-around
        .button
            height: 36px
            &.second
                border: 1px solid lightgrey
                font-size: 12px
                display: flex
                flex-direction: row
                justify-content: center
                gap: 6px
                .icon
                    filter: grayscale(0.7)
                &:hover
                    border-color: $color-brand
                    .icon
                        filter: none
        .photo
            position: relative
            img
                width: 220px
                height: 120px
                object-fit: cover
                border-radius: 4px
        .short-details
            margin-top: 25px
            font-size: 14px
            display: flex
            flex-direction: column
            gap: 8px
            padding-right: 60px
        h1
            font-size: 30px !important
            line-height: 38px !important
            margin-bottom: 0 !important
        .stars
            margin: 0 10px 3px 0 !important
    @include desktop
        .two-column-wrapper
            flex-grow: 1
            position: relative
            overflow: hidden
            display: flex
            .column
                height: 100%
                padding: 4px 25px 4px 1px
                &.left
                    min-width: 265px
                    max-width: 265px
                    .filters
                        margin-top: -22px
                        padding-bottom: 30px
                &.right
                    flex-grow: 1
        .upper-part
            .button-toggle-filters
                display: none
            .filtration > .item:first-child
                margin-right: 0
            .right
                padding-right: 25px
    @include except-phone
        .upper-part
            display: flex
            justify-content: space-between
    @include laptop
        .two-column-wrapper
            .column.left
                display: none
