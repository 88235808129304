.input-range__slider
    appearance: none
    background: $color-primary
    border: 1px solid $color-primary
    border-radius: 100%
    cursor: pointer
    display: block
    margin-left: -10px
    margin-top: -13px
    outline: none
    position: absolute
    top: 13px
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out
    width: 20px
    height: 18px

.input-range__slider:active
    transform: scale(1.1)

.input-range__slider:focus
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2)

.input-range--disabled .input-range__slider
    background: #cccccc
    border: 1px solid #cccccc
    box-shadow: none
    transform: none

.input-range__slider-container
    transition: left 0.3s ease-out

.input-range__label
    color: #aaaaaa
    font-size: 0.8rem
    transform: translateZ(0)
    white-space: nowrap

.input-range__label--min,
.input-range__label--max
    bottom: -18px
    position: absolute

.input-range__label--min
    left: 0

.input-range__label--max
    right: 0

.input-range__label--value
    position: absolute
    top: -1.8rem

    .input-range__label-container
        display: none

.input-range__label-container
    font-size: 12px
    font-weight: 500
    line-height: 14px
    color: $color-black

.input-range__track
    background: #F4F4F4
    border-radius: 8px
    cursor: pointer
    display: block
    height: 8px
    top: 12px
    position: relative
    transition: left 0.3s ease-out, width 0.3s ease-out

.input-range--disabled .input-range__track
    background: #eeeeee

.input-range__track--background
    left: 8px
    margin-top: -4px
    position: absolute
    right: 8px
    top: 50%

.input-range__track--active
    background: $color-brand

.input-range
    user-select: none
    height: 33px
    position: relative
    width: 100%
    margin-bottom: 15px
    padding-left: 8px
