.custom-search-notification
    position: sticky
    top: 79px
    z-index: 160
    background-color: $color-background
    border: 1px solid $color-border
    padding: 14px 0
    @include tablet
        top: 80px
        padding: 0
    section
        display: flex
        align-items: center
        justify-content: space-between
    div
        display: flex
        align-items: center
    strong
        font-weight: 600
    button
        height: 30px
        @include tablet
            padding: 2px 8px
            height: 26px
    .icon-information
        scale: 0.65
