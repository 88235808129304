.modal-wrapper
    width: 100%
    height: 100%
    position: fixed
    z-index: 5000
    top: 0
    left: 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    overflow-y: scroll
    .hide-close-button
        .close-button
            display: none

.modal-scroll
    text-align: center
    margin: auto
    max-height: 100%
    overflow: auto
    padding: 20px 0 35px
    width: 100%

.big-modal-wrapper
    .modal-scroll
        overflow: visible

.overlay
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    background: #f9f7f4
    opacity: .4

.auth-modal-overlay
    &:after
        display: block
        position: fixed
        top: 0
        left: 50%
        margin-left: -69px
        content: ''
        width: 137px
        height: 74px
        background: url("/images/logo/logo.png") no-repeat
        z-index: 50000
        background-size: contain
    .overlay
        opacity: 1
        background: url('/images/account/bg02.svg') no-repeat center 70%
        background-size: cover

.modal
    text-align: left
    margin: 0 auto
    position: relative
    background: #ffffff
    border-radius: 25px
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2)
    padding: 40px
    max-width: 480px
    width: auto
    display: inline-block
    &.wide
        max-width: 640px
    &.no-padding
        padding: 0
    @include tablet
        max-width: 90% !important
    h2
        line-height: 24px
        font-size: 18px
        font-weight: 500
    .title
        padding: 18px 26px
        border-bottom: 1px solid $color-border
    .content
        padding: 30px 25px 35px
    .danger
        color: $color-red
    h2
        margin-bottom: 24px
    p
        margin-bottom: 12px
    .button
        padding-left: 40px
        padding-right: 40px
        margin-right: 20px
    .bottom
        margin-top: 30px
        text-align: center
        .button
            width: 100%
    .row+.bottom
        margin-top: 0
    &.wide
        width: 100%
        .textarea
            min-height: 120px !important

.body-wrapper
    min-width: 480px

body.modal-open
    overflow: hidden
    .body-wrapper > div, header, footer
        filter: blur(2px)

.close-button
    z-index: 1
    position: absolute
    right: 5px
    top: 5px
    padding: 10px 10px 5px 5px
    cursor: pointer
    .icon
        display: block
        filter: brightness(0)
        opacity: .4
    &:hover
        .icon
            filter: none
            opacity: 1

.auth-modal-background
    background: #ffffff url('/images/account/modal-bg.svg') no-repeat center top
    background-size: 500px
    padding-top: 120px

.cancellation-room-modal
    max-width: 900px
    padding: 30px 0
    @include desktop
        min-width: 600px
    h2
        text-align: center
    strong
        font-size: 18px
        font-weight: 600
        &.cancellation-cost
            color: $color-red
        &.final-price
            color: $color-green
    .text
        padding: 0 50px
        line-height: 28px
        font-size: 14px
        text-align: center
    .table .the-table tr td:last-child
        padding-right: 10px
    .bottom
        text-align: center
        .button
            width: auto

.accommodation-details-modal
    max-width: 1300px
    width: 85vw
    padding: 0
    @include tablet
        width: 100vw
    .search-results.block
        padding: 0
    h2
        margin-bottom: 12px
    .gallery
        margin: 20px 0 20px !important
        @include tablet
            margin-bottom: 40px !important
    .inner
        padding: 10px 27px 60px
        @include tablet
            padding: 10px 15px 30px
