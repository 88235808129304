.dropdown
    position: absolute
    background: #FFFFFF
    color: $color-text
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15)
    border-radius: 25px
    z-index: 100
    margin-top: 10px
    overflow: hidden
    left: 0
    width: 100%
    max-height: 280px
    min-width: 120px
    animation: Appear .15s ease-in 1
    font-size: 14px
    b
        font-weight: 600
    .scroll
        padding: 8px 0
        max-height: inherit
        overflow-y: auto
        &::-webkit-scrollbar
            width: 7px
        &::-webkit-scrollbar-track
            background: transparent
        &::-webkit-scrollbar-thumb
            background-color: $color-border
    .line
        cursor: pointer
        position: relative
        z-index: 1
        line-height: 22px
        padding: 9px 15px 9px 27px
        display: flex
        font-weight: 400
        margin-bottom: 0
        .flag
            margin-right: 12px
        &:hover, &.focused
            background: #F3F4F6
        em
            font-style: normal
            color: $color-text-light
            margin-left: 4px
    .subtitle
        height: 24px
        line-height: 24px
        padding: 0 15px 0 27px
        margin: 8px 0 2px
        font-size: 12px
        font-weight: 300
        color: $color-text-light
        &:first-child
            margin-top: 0

#destinationInput
    .line
        justify-content: space-between
        align-items: center
    .temporary-technical-button
        display: inline-block
        border: 1px solid $color-border
        text-align: center
        border-radius: 12px
        margin-left: 6px
        min-width: 80px
        max-width: 80px
        height: 24px
        font-size: 12px
        color: $color-text-light
        &:hover
            background: #ffffff
            color: $color-text
        .icon
            position: relative
            top: -1px
            margin-right: 2px
