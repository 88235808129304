.stars
    position: relative
    top: 1px
    i
        display: inline-block
        background-repeat: no-repeat
        background-position: center center
        vertical-align: baseline
        width: 12px
        height: 12px
        background-image: url("./imgs/star.svg")
        background-size: cover
