.search-results.block
    .text
        margin-bottom: 34px
        font-size: 14px
        line-height: 24px
        .cut
            max-height: 200px
            overflow: hidden
            margin-bottom: 2px
            position: relative
            & > div
                padding-bottom: 10px
            &:before
                content: ' '
                width: 100%
                height: 23px
                position: absolute
                left: 0
                bottom: 0
                background: linear-gradient(rgba(255, 255, 255, 0), #ffffff)
        p, b, i, ul, li, h1, br
            color: inherit
        p
            margin: 2px 0 3px
        h1
            margin: 3px 0 5px
            font-size: 17px
        ul
            padding-left: 16px
        li
            list-style: disc
            margin-top: 2px

    .pager
        margin-bottom: 0
        @include desktop
            margin-bottom: 20px
        @include tablet
            margin-bottom: 20px
