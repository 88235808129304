@mixin icon($image, $width: 18, $height: 18)
    background-image: url($image)
    width: #{$width}px
    height: #{$height}px
.icon
    display: inline-block
    background: no-repeat center center
    vertical-align: text-bottom
    &.icon-mastercard
        @include icon("./images/landing/footer/mastercard.svg", 60, 38)
    &.icon-mastercard-id
        @include icon("./images/landing/footer/mastercard-id.svg", 38, 38)
    &.icon-visa
        @include icon("./images/landing/footer/visa.svg", 56, 38)
    &.icon-visa-secure
        @include icon("./images/landing/footer/visa-secure.svg", 38, 38)
    &.icon-amex
        @include icon("./images/landing/footer/amex.svg", 38, 38)

    &.icon-plane
        @include icon("./images/landing/plane.svg", 22, 22)
    &.icon-burger-menu
        @include icon("./images/landing/header/burger-menu.svg", 16, 16)
    &.icon-cross
        @include icon("./images/landing/header/cross.svg", 16, 16)

    &.icon-arrow
        @include icon("./images/landing/arrow.svg", 16, 16)
    &.icon-place
        @include icon("./images/landing/home/place.svg", 14, 16)

    &.icon-minus
        @include icon("./images/landing/minus.svg", 18, 18)
    &.icon-avatar
        @include icon("./images/landing/avatar.svg", 32, 32)
