.lg-footer
    color: $color-black
    background-color: $color-background-gray
    padding: 40px 0
    margin: 0

    @include mobile
        padding-top: 0

    section
        margin: 0 auto
        display: flex
        flex-direction: column

    .links-wrapper
        width: 100%
        margin: 0 auto
        padding-bottom: 40px
        display: flex
        flex-direction: column
        gap: 30px
        border-bottom: 1px solid $color-black
        @include mobile
            justify-content: flex-start
            align-items: flex-start
            gap: 48px

    .logo
        width: 112px
        margin: 0 auto 40px

    .links-group
        display: flex
        justify-content: space-between
        align-items: center
        gap: 16px
        @include mobile
            flex-wrap: wrap
            gap: 24px
            width: 100%

        &:last-child
            min-width: 40%
            @include laptop
                width: 100%
                justify-content: space-around
            @include mobile
                width: auto

    .image
        width: 100%
        max-width: 1200px
        max-height: 250px
        margin: 30px auto
        &.pointer
            cursor: pointer
    .image-logo
        position: absolute
        top: 70px
        right: 40px
        max-width: 500px
        @include laptop
            top: 50px
            right: 20px
            max-width: 400px
        @include mobile
            max-width: 300px
    .info-wrapper
        padding-top: 40px

    .info
        margin-bottom: 24px
        display: grid
        grid-template-columns: 30% repeat(3, 1fr)
        grid-column-gap: 16px

        @include laptop
            grid-template-columns: repeat(2, 1fr)

        @include tablet
            grid-template-columns: repeat(1, 1fr)

        p
            margin-bottom: 12px
        span
            color: $color-gray

    .contact-link
        color: $color-gray !important

    .icons-group
        display: flex
        gap: 16px
        flex-wrap: wrap
        justify-content: end

        @include laptop
            justify-content: start
        @include tablet
            margin: 16px 0
