.rooms
    min-height: 65vh
    .group
        margin: 12px 0
        .name
            background: $color-background
            padding: 20px 60px 20px 20px
            display: flex
            justify-content: space-between
            font-weight: 500
            font-size: 15px
            line-height: 18px
            cursor: pointer
            border-radius: 8px
            position: relative
            .icon-big-expand
                position: absolute
                top: 14px
                right: 14px
                cursor: pointer
            .min-price
                font-size: 13px
    .name+.room, .room:first-child
        border-top: 1px solid #D8DADC
    .room
        margin-top: 12px
        padding: 16px 20px
        font-size: 12px
        line-height: 18px
        border-bottom: 1px solid #D8DADC
        &:hover
            @include except-phone
                .next-button
                    border-color: #fff
                    background: #FF7A00
                    background: linear-gradient(90deg, #FF7A00 0%, #F9A51B 100%)
        .show-phone, .phone-tags
            display: none
        .room-details
            display: flex
            justify-content: space-between
            cursor: pointer
            position: relative
            &[data-is-agency-search=true]
                cursor: auto
            .action-buttons
                padding: 0 20px
                display: flex
                align-items: center
                flex-direction: row
                gap: 10px
                .button
                    height: 38px
                    padding: 3px 14px
                    width: max-content
            &>div
                display: flex
                flex-direction: column
            @include tablet
                flex-wrap: wrap
                justify-content: flex-start
                .info
                    width: 60%
                    max-width: 60%
                .price
                    width: 30%
                    max-width: 30%
                .button-holder
                    width: 10%
                    max-width: 10%
                .deadline-column, .meal-plan, .hide-phone
                    display: none
        @include tablet
            .show-phone
                display: inline-block
            .phone-tags
                display: block
                padding: 10px 20px 0 0
                .tag
                    div, span
                        display: inline
        .info
            width: 45%
            padding-right: 20px
        .deadline-column
            width: 20%
            line-height: 20px
            padding-right: 20px
        .deadline-column, .deadline-tag
            span
                display: block
            .refundable
                color: #33AE3C
            .within-deadline
                color: #797F89
            .non-refundable
                color: #EB001B
        .meal-plan
            width: 15%
            padding-right: 20px
        .price
            width: 15%
        .button-holder
            width: 5%
        .info
            padding-right: 30px
            h3
                font-size: 14px
                line-height: 18px
                margin-bottom: 10px
                font-weight: 500
                display: flex
                flex-direction: row
                align-items: center
                color: #016FD0
            &>div
                color: $color-text
                margin-bottom: 4px
        .label
            color: #777
            margin-bottom: 8px
            font-size: 11px
            font-weight: 400
            line-height: 13px
            display: block
        strong
            color: $color-text
        .meal-plan, .deadline-column
            font-size: 14px
        .price
            text-align: right
            width: 150px
            min-width: 120px
            padding-right: 10px
            .daily-rates-button-holder
                button
                    margin-top: 2px
                    padding: 0
                    margin-right: -2px
                    white-space: nowrap
                    .icon-big-expand
                        width: 17px
                        height: 17px
                        margin-left: 4px
        .button-holder
            min-width: 40px
            align-items: flex-end
            justify-content: center
            padding-bottom: 30px
            .button
                width: 34px
                height: 34px
                padding: 0
    .tag
        margin-left: 0
        margin-right: 4px

.tag
    margin: 5px 18px -8px -9px
    padding: 2px 8px
    border: 1px solid $color-border
    font-size: 11px
    background: #fff
    display: inline-block
    color: $color-text
    border-radius: 3px
    &.clickable
        &:hover
            border-color: $color-brand
            color: $color-brand
    .icon
        width: 13px
        height: 13px
        background-size: contain
        margin-right: 6px

.daily-rates-contract-table
    line-height: 20px
    border: 1px solid $color-background
    border-radius: 10px
    overflow: hidden
    margin-top: 5px
    & > div:first-child
        padding: 6px 25px
    & > div
        display: flex
        align-items: center
    & > div:nth-child(odd)
        background: $color-background
    .left, .right
        flex-grow: 1
        @include tablet
            span
                display: block
    .right
        text-align: right
        @include tablet
            width: 100px
    .room-inner
        display: flex
        flex-direction: column
        flex-grow: 1
        & > div
            padding: 14px 25px
            display: flex
            align-items: center
        & > div:nth-child(odd)
            background: #fff
        & > div:nth-child(even)
            background: $color-background
    .room-dates
        display: flex
    &.rates-big
        .room-inner
            padding: 5px 0
        .room-name
            max-width: 200px
            flex-grow: 2
            @include tablet
                max-width: 140px
        .room-dates
            padding: 4px 0
        .left, .room-name
            padding-left: 25px
        .right, .room-name
            padding-right: 25px
        & > div
            padding: 0
.final-price-modal
    .title
        padding: 8px 0 16px
        margin: 0 0 16px
    .photo-holder, .button, .tag
        display: none
    .copier-wrapper
        margin-top: 0
