.rdp
    --rdp-cell-size: 36px
    --rdp-accent-color: #f9a51a
    --rdp-background-color: #fdfbf9
    --rdp-accent-color-dark: #f9a51a
    --rdp-background-color-dark: #110700
    --rdp-outline: 2px solid #ffffff
    --rdp-outline-selected: 3px solid #ffffff
    margin: .5em

.rdp-table
    overflow: hidden

.rdp-day:hover
    color: $color-primary

.rdp-caption_label
    font-size: 16px !important
    font-weight: 500 !important
    line-height: 20px !important
    margin: 0 !important

.rdp-caption
    margin-bottom: 20px

.rdp-head_cell
    color: $color-text-light
    font-weight: 600

.rdp-day_today
    font-weight: 400 !important

.rdp-day
    margin: 2px

.rdp-nav_button_previous[disabled]
    visibility: hidden

.rdp-day_selected.rdp-day_range_start,
.rdp-day_selected.rdp-day_range_end
    border-radius: 40px !important
    border: 0 !important
    background: linear-gradient(270deg, #FAAB19 0%, #FECD0A 100%)
    position: relative
    z-index: 5
    &:before, &:after
        display: none !important
    &:hover
        color: #ffffff

.rdp-day.first-in-range,
.rdp-day.last-in-range
    overflow: visible

.rdp-day.first-in-range:before,
.rdp-day.last-in-range:after
    content: ''
    display: block
    width: 24px
    height: 36px
    position: absolute
    top: 50%
    margin-top: -18px
    left: 32px
    background: #f8f8f8
    z-index: 1
.rdp-day.first-in-range:before
    left: auto
    right: 32px

.rdp-day_range_middle
    background: #f8f8f8
    color: $color-text
    margin-left: 0
    margin-right: 0
    width: 40px
    max-width: 40px
    &:hover
        background: #F4F4F4

@include tablet
    .rdp-month
        position: relative
        z-index: 1
    .rdp-caption_end
        position: static
        .rdp-caption
            position: static
            .rdp-caption_label
                display: none
            .rdp-nav
                z-index: 3
                top: 44px
                right: 38px
