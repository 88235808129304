*
    margin: 0
    padding: 0
    outline: none
    box-sizing: border-box

menu, nav, li, ul
    list-style: none

a, a[href]
    color: inherit
    text-decoration: none
    cursor: pointer

h1, h2, h3, h4, h5, h6
    font-weight: 400
