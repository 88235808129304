$color-brand: #f9a51a
$color-text: #231f20
$color-text-light: #777777
$color-blue: #1267fb
$color-green: #1CBE69
$color-red: #E25141
$color-yellow: #f9a61b
$color-background-yellow: #F1EDE7
$color-border: #e5e5e5
$color-light-blue: #e6effd
$color-dark-gray: #818283
$color-shadow-border: #B9B9B9
$color-background: #F7F8FC
$color-not-loaded: #F0F5F9
