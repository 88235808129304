.home-main-block
    position: relative
    background-image: url('/images/landing/home/background.svg')
    background-position: 50% 0
    background-repeat: no-repeat
    background-size: 100%

    @include laptop
        background: none

    .heading
        margin: 0 auto
        max-width: 600px
        display: flex
        flex-direction: column

    .title
        margin: 36px 0 32px
        text-align: center

        @include tablet
            margin-bottom: 20px

    .text
        text-align: center
        margin-bottom: 38px

        @include tablet
            margin-bottom: 24px

    .request-button
        margin: 0 auto

        @include tablet
            width: 100%
            button
                width: 100%

    .content
        margin-top: -68px
        width: 100%
        height: 460px
        display: grid
        grid-template-columns: 0.8fr 1fr 1.3fr 0.8fr 1.1fr
        align-items: end
        grid-gap: 32px

        @include large-laptop
            grid-template-columns: 0.8fr 1fr 1fr 0.8fr 1.1fr
            grid-gap: 16px

        @include laptop
            margin-top: 0
            height: auto
            grid-template-columns: 0.9fr 0.9fr 1.2fr 1fr
            grid-row-gap: 16px

        @include tablet
            margin-top: 32px

        @include mobile
            grid-template-columns: repeat(3, 1fr)
            grid-template-rows: auto auto auto

        img
            max-height: 460px
            border-radius: 20px
            width: 100%
            height: 100%
            object-fit: cover

        .inside-info
            position: absolute
            top: 0
            left: 0
            height: 100%
            width: 100%
            display: flex
            flex-direction: column
            justify-content: space-between

        .dark-link
            border-bottom: 1px solid $color-black
            width: fit-content

        .second-block, .fourth-block
            height: 80%

        .second-block
            border-radius: 20px
            background-color: $color-background-gray
            display: flex
            align-items: center

            @include laptop
                height: 70%
            @include mobile
                height: 100%

            img
                height: auto
                max-height: 100%

        .third-block
            height: 220px

            @include laptop
                height: 70%

            @include mobile
                height: 150px
                width: 100%
                grid-column: 1 / span 3
                grid-row: 3 / span 1

                img
                    object-position: 0 70%

            .destination-plate
                border-radius: 14px
                margin: 20px
                padding: 10px 24px
                background-color: $color-white
                width: fit-content

            .destination-label
                opacity: 0.5
                font-size: 11px
                line-height: 16px
                margin-bottom: 6px

            .destination-place
                display: flex
                align-items: center

            .place-name
                margin-left: 6px
                font-size: 15px
                font-weight: 500
                line-height: 0

            .arrow-wrapper
                margin-left: auto
                margin-right: 20px
                width: 48px
                height: 50px
                border-radius: 30px 30px 0 0
                background-color: $color-white

        .third-block, .fourth-block
            position: relative

        .fourth-block
            @include laptop
                height: 100%

            .inside-info
                .circle-arrow
                    margin: 16px 16px 0 auto
                .icon-arrow
                    transform: rotate(-135deg)

            .heading
                background-color: $color-white
                border-radius: 14px
                margin: 16px
                font-size: 20px
                font-weight: 500
                padding: 18px 14px

                @include large-laptop
                    margin: 8px
                    padding: 10px
                    font-size: 16px

        .fifth-block
            min-height: 100%
            background-color: $color-background-gray
            border-radius: 20px
            display: flex
            flex-direction: column
            text-align: center

            @include laptop
                height: auto
                flex-direction: row
                grid-column: 1 / span 4

            @include mobile
                background-color: $color-white
                display: flex
                gap: 16px
                margin-right: 16px

            h3
                line-height: 150%

                @include large-laptop
                    font-size: 22px

            .icon-arrow
                transform: rotate(225deg)

            .circle-arrow
                margin: 24px 20px 8px auto
                @include laptop
                    margin: 0 0 0 auto

            .info
                flex: 1
                display: flex
                flex-direction: column
                align-items: center
                padding: 8px 20px
                border-radius: 20px

                @include laptop
                    padding: 20px

                @include mobile
                    padding: 8px

                &:first-child
                    justify-content: center
                    background-color: $color-background-gray

                    p
                        font-size: 14px
                        opacity: 0.7

                &:last-child
                    background-color: $color-background-brown
                    border-radius: 20px
                    font-weight: 500

                    @include mobile
                        padding-bottom: 30px

                    p
                        margin-bottom: 22px
                        font-size: 18px
                        font-weight: 500

                        @include mobile
                            margin-bottom: 8px

.home-technology-block
    .text-block
        margin-bottom: 76px

    .link-button
        @include tablet
            width: 100%

            button
                width: 100%

    .swiper-cards
        @include tablet
            .slider-track
                flex-direction: column
                width: fit-content

            .circle-arrow
                display: none

        .card
            height: 440px
            border-radius: 24px
            padding: 50px 40px
            overflow: hidden

            &:first-child
                width: 670px
                display: flex
                gap: 16px
                background-color: $color-background-gray

                @include tablet
                    width: 100%
                    flex-direction: column

            &:nth-child(2)
                max-width: 440px
                background-color: $color-background-brown
                background-image: url("/images/landing/home/pattern1.svg")
                background-repeat: no-repeat
                background-position: 105% 110%

                @include tablet
                    max-width: 100%

            &:last-child
                max-width: 440px
                background-color: $color-background-brown
                background-image: url("/images/landing/home/pattern2.svg")
                background-repeat: no-repeat
                background-position: 105% 115%

                @include tablet
                    max-width: 100%

            @include tablet
                height: fit-content
                padding: 32px 16px

        .text
            font-size: 18px
            font-weight: 400
            margin: 28px 0 48px

            @include tablet
                margin-bottom: 20px

        .phone-img-wrapper
            width: fit-content
            height: 200px

            @include tablet
                display: flex
                width: 100%
                height: 470px
                margin-top: 20px
                margin-bottom: -40px

        .phone-img
            max-width: 244px
            object-fit: cover
            object-position: top

            @include tablet
                max-width: fit-content
                width: 45%
                margin: 0 auto

            @include mobile
                width: 60%
