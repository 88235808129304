.table
    max-width: 100%
    overflow-y: auto
    @include tablet
        padding-bottom: 30px
    .the-table
        border-collapse: collapse
        width: 100%
        font-size: 13px
        line-height: 16px
        tr
            border-top: 1px solid $color-border
            &:last-child
                border-bottom: 1px solid $color-border
            &.clickable
                cursor: pointer
                &:hover
                    td
                        background: #F3F4F6
            td
                vertical-align: top
                padding: 35px 30px 35px 0
                @include laptop
                    padding-right: 10px
                &:first-child
                    padding-left: 40px
                &:last-child
                    padding-right: 25px

            & .children-row
                background-color: $color-background
            .parent-cell
                display: flex
                align-items: center
                gap: 32px
            .arrow-wrapper
                background-color: $color-background
                width: 24px
                height: 24px
                display: flex
                align-items: center
                justify-content: center
                border-radius: 50%
                transition: 0.3s all
            .icon.icon-arrow-next
                filter: brightness(.5)
                transform: scale(0.7)
            .icon-rotated
                transform: rotate(90deg)
        strong
            font-size: 11px
            line-height: 13px
            color: $color-text-light
            display: block
            font-weight: 400
            margin-bottom: 12px

    .empty
        border: solid $color-border
        font-size: 17px
        font-weight: 300
        border-width: 1px 0
        text-align: center
        padding: 120px 0
        background: #fcfcfc

    .icon-search
        background-position: 0 3px

    .controls
        display: flex
        justify-content: space-between
        .form
            padding: 30px 0
            margin-right: 16px
            width: 260px
        .search-wrap
            margin-right: 0
        .left
            display: flex
            @include tablet
                flex-direction: column
                .form+.form
                    margin-top: -40px
