.cabinet
    section
        max-width: 886px
        padding-bottom: 150px
        min-height: 500px
    h2
        margin: 50px 0 35px
        font-size: 20px
        line-height: 24px
        color: #231F20
    .row
        margin-bottom: 18px
    .form
        .row.controls
            padding: 0 !important
            margin-top: 9px
            margin-bottom: 0
            .button
                min-width: 215px
            .field
                flex-grow: 0
                width: auto
            @include tablet
                .field
                    width: 100%
        &.agent-data
            .row
                width: 60%
                @include tablet
                    width: 100%
        &.app-settings
            @include except-phone
                .field
                    width: 50%
            @include tablet
                .double
                    flex-direction: column
                    margin-bottom: 0
                    .field
                        margin-right: 0
                        margin-bottom: 20px
    .roles-wrapper
        display: flex
        align-content: flex-start
        @include tablet
            flex-direction: column
        .roles
            flex-wrap: wrap
            display: flex
            flex-direction: column
            .item
                font-weight: 500
                margin-bottom: 18px
        .permissions
            .title
                display: block
                text-transform: uppercase
                line-height: 16px
                font-size: 11px
                color: $color-text-light
                font-weight: 600
                margin-bottom: 4px
            .allotment li
                font-size: 13px
            li
                margin-bottom: 2px
            ul
                margin-bottom: 20px
            .gray
                color: $color-border
    h2.in-table + .table
        margin-top: -110px
    .search-wrapper
        padding: 73px 0 82px
        max-width: 740px
        .field:last-child
            max-width: 330px
    .breadcrumbs
        margin: 45px 0 -25px
