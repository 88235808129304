@property --num
    syntax: '<integer>'
    initial-value: 0
    inherits: false

.our-story-block
    margin-top: 114px
    display: grid
    grid-template-columns: 2fr 3fr
    align-items: stretch
    gap: 52px

    @include large-laptop
        gap: 16px

    @include tablet
        margin-top: 56px
        grid-template-columns: 1fr

    .icon-plane
        margin: 0 20px

    .title
        text-align: left
        margin-bottom: 32px

        @include laptop
            margin-bottom: 16px

    .text
        margin-bottom: 38px

        @include laptop
            margin-bottom: 16px

    .learn-more-button
        @include tablet
            width: 100%
            button
                width: 100%

    .info-plate
        margin-top: 28px
        margin-left: auto
        width: fit-content
        display: flex
        align-items: center
        padding: 22px 20px
        border-radius: 20px
        background-color: $color-background-gray

        @include laptop
            display: none

        p
            font-size: 14px
            opacity: 0.7

        .arrow-wrapper
            margin-left: 20px
            width: 40px
            height: 60px
            border-radius: 30px
            background-color: $color-white

        .counter
            transition: --num 3s
            counter-reset: num var(--num)

            &::after
                content: counter(num)

    .images-content
        display: flex
        gap: 52px
        height: 100%
        justify-content: flex-end

        @include large-laptop
            grid-gap: 16px

        @include mobile
            flex-direction: column

        img
            border-radius: 20px
            width: 100%
            max-height: 100%
            object-fit: cover
            height: auto

        .main-image
            height: 100%
            width: 55%
            min-width: 320px
            img
                height: 100%
                min-height: 100%
                object-position: 35% 0

                @include mobile
                    object-position: 0

            @include laptop
                min-width: 280px

            @include tablet
                width: auto

            @include mobile
                max-height: 280px

        .inside-info
            position: absolute
            top: 0
            left: 0
            height: 100%
            width: 100%
            display: flex
            flex-direction: column
            justify-content: space-between

        .product-block-wrapper
            display: flex
            flex-direction: column
            gap: 18px

            @include mobile
                flex-direction: row
                height: 160px

            .inside-info
                .circle-arrow
                    margin: 16px 16px 0 auto
                .icon-arrow
                    transform: rotate(-135deg)

        .product-block
            height: 60%
            position: relative

            @include mobile
                height: auto
                flex: 1

            img
                height: 100%

            .heading
                background-color: $color-white
                border-radius: 14px
                margin: 16px
                font-size: 20px
                font-weight: 500
                padding: 18px 14px

                @include large-laptop
                    margin: 8px
                    padding: 10px
                    font-size: 16px

        .join-block
            flex: 1
            position: relative
            height: max-content
            border-radius: 20px
            background-color: $color-background-brown
            background-image: url("/images/landing/about-us/pattern1.svg")
            background-repeat: no-repeat
            background-position: 100% 0

            @include mobile
                height: 100%
            h3
                margin: 0 32px 14px 32px

                @include laptop
                    font-size: 24px
                    margin: 0 8px 10px 10px

.home-technology-block
    .info-wrapper
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 20px
        margin-bottom: 76px

        @include laptop
            grid-template-columns: 1fr

    p
        margin-bottom: 46px
        @include tablet
            margin-bottom: 24px

    .icon-plane
        margin: 0 20px

    .link-button
        @include tablet
            width: 100%

            button
                width: 100%

    .swiper-cards
        @include tablet
            .slider-track
                flex-direction: column
                width: fit-content

            .circle-arrow
                display: none

        .card
            height: 440px
            border-radius: 24px
            padding: 50px 40px
            overflow: hidden

            &:first-child
                width: 670px
                display: flex
                gap: 16px
                background-color: $color-background-gray

                @include tablet
                    width: 100%
                    flex-direction: column

            &:nth-child(2)
                max-width: 440px
                background-color: $color-background-brown
                background-image: url("/images/landing/home/pattern1.svg")
                background-repeat: no-repeat
                background-position: 105% 110%

                @include tablet
                    max-width: 100%

            &:last-child
                max-width: 440px
                background-color: $color-background-brown
                background-image: url("/images/landing/home/pattern2.svg")
                background-repeat: no-repeat
                background-position: 105% 115%

                @include tablet
                    max-width: 100%

            @include tablet
                height: fit-content
                padding: 32px 16px

        .text
            font-size: 18px
            font-weight: 400
            margin: 28px 0 48px

            @include tablet
                margin-bottom: 20px

        .dark-link
            border-bottom: none
            position: relative

            &::before
                content: ""
                position: absolute
                display: block
                bottom: -2px
                height: 1px
                width: 0
                left: 0
                background-color: currentcolor
                transition: all .2s ease
                transition-delay: .2s

            &::after
                content: ""
                position: absolute
                display: block
                bottom: -2px
                height: 1px
                width: 100%
                right: 0
                background-color: currentcolor
                transition: all .2s ease

            &:hover
                &::before
                    width: 100%

                &::after
                    width: 0

        .phone-img-wrapper
            width: fit-content
            height: 200px

            @include tablet
                display: flex
                width: 100%
                height: 470px
                margin-top: 20px
                margin-bottom: -40px

        .phone-img
            max-width: 244px
            object-fit: cover
            object-position: top

            @include tablet
                max-width: fit-content
                width: 45%
                margin: 0 auto

            @include mobile
                width: 60%

.join-block
    img
        flex: 1
        width: 100%
        border-radius: 20px
        object-fit: cover
    .text-block
        grid-gap: 40px
        justify-items: end
    .text-wrapper
        max-width: 600px
        @include laptop
            max-width: none

.team-block
    .text-block
        margin-bottom: 76px

    .team-wrapper
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-template-rows: auto
        gap: 16px
        row-gap: 32px

        @include large-laptop
            grid-template-columns: repeat(3, 1fr)
        @include laptop
            grid-template-columns: repeat(2, 1fr)
        @include mobile
            grid-template-columns: 1fr

    .description
        flex: 1
        position: relative
        height: 350px
        margin-bottom: 28px
        border-radius: 24px
        background-color: $color-background-brown
        padding: 30px 20px
        .photo
            background-color: $color-background-brown
        .icon-arrow
            transform: rotate(-135deg)
        .circle-arrow
            width: 48px
            height: 48px
            margin-left: auto

    .gray-description
        background-color: $color-background-gray
        .photo
            background: $color-background-gray

    .photo
        position: absolute
        top: 0
        left: 0
        z-index: 0
        border-radius: 24px
        aspect-ratio: 1 / 1
        width: 100%
        height: 100%
        object-fit: cover
        opacity: 1
        transition: 0.5s opacity

    .card
        min-height: 450px
        max-width: 340px
        display: flex
        flex-direction: column

        &:hover
            .photo
                opacity: 0

        @include laptop
            max-width: 100%
            width: 100%
            &:hover
                .photo
                    opacity: 1

    .icon-wrapper
        cursor: pointer
        position: relative
        margin-left: auto
        margin-bottom: 6px
        z-index: 10
        width: 48px
        height: 48px
        border-radius: 50%
        background-color: $color-white
        justify-content: center
        align-items: center
        transition: 0.5s opacity
        display: none

        @include laptop
            display: flex

        .icon-plus
            position: absolute
            transform: rotate(90deg)
            transition: 0.3s all

    .opened-card-description
        @include laptop
            .icon-plus
                transform: none

            .photo
                opacity: 0 !important

    .text-wrapper
        height: 94px

    .name
        margin-bottom: 22px
        font-size: 24px
        font-weight: 500

    .position
        font-size: 20px

    .link-card
        background-image: url("/images/landing/about-us/work-with-us.png")
        background-size: cover
        display: flex
        flex-direction: column
        justify-content: space-between
        padding: 5px

        h2
            font-size: 40px
            margin-bottom: 8px
            @include laptop
                font-size: 32px

        .arrow-wrapper
            margin: 15px 15px 0 0

        .link-card-text-holder
            background: rgba(255, 255, 255, .7)
            border-radius: 24px
            padding: 5px 20px 15px
            &, p
                color: #000

.open-positions-block
    .block-heading
        margin-bottom: 60px
        display: flex
        align-items: center
        justify-content: space-between

        .arrows-wrapper
            display: flex
            gap: 16px

        .circle-arrow
            position: relative
            transform: none
            background-color: $color-background-gray
            width: 48px
            height: 48px

        .circle-arrow[data-disabled=true]
            pointer-events: none

            .icon-arrow
                opacity: 0.5

    .swiper-cards
        .card
            height: 290px
            border-radius: 24px
            padding: 50px 40px
            display: flex
            flex-direction: column
            justify-content: space-between
            position: relative
            width: 380px
            background-color: $color-background-gray

            .icon-arrow
                transform: rotate(-135deg)
            .circle-arrow
                width: 48px
                height: 48px
                margin-left: auto
                position: static
                transform: none

            &:nth-child(2)
                width: 420px
                background-color: $color-background-brown
                background-image: url("/images/landing/about-us/pattern3.svg")
                background-repeat: no-repeat
                background-position: 10% 160%

            @include tablet
                height: fit-content
                padding: 32px 16px

        .text
            font-size: 18px
            font-weight: 400
            margin: 28px 0 16px

            @include tablet
                margin-bottom: 20px

        .discover-card
            width: 214px
            border-radius: 24px
            padding: 18px
            display: flex
            flex-direction: column
            justify-content: space-between
            position: relative
            background: url("/images/landing/about-us/sea.png")
            background-repeat: no-repeat
            background-size: cover

            @include tablet
                width: 380px

            .icon-arrow
                transform: rotate(-135deg)
            .circle-arrow
                margin-left: auto
                position: static
                transform: none

            .heading
                background-color: $color-white
                border-radius: 14px
                font-size: 20px
                font-weight: 500
                padding: 18px 14px

                @include large-laptop
                    margin: 8px
                    padding: 10px
                    font-size: 16px
