.flag
    width: 15px
    height: 10px
    position: relative
    display: inline-block
    font-size: 11px
    span
        outline: 1px solid rgba(128,128,128,.22)
        &.np
            outline: 0
    .flag-icon
        width: 100%
        height: 100%
