.status
    &.Success,
    &.Confirmed,
    &.Completed
        color: $color-green
    &.Cancelled,
    &.Rejected,
    &.Discarded,
    &.PendingCancellation,
    &.AwaitingCancellation,
    &.Error
        color: $color-red
