$media-large-laptop: 1200px
$media-laptop: 1024px
$media-tablet: 768px
$media-mobile: 640px

@mixin tablet
  @media (max-width: #{$media-tablet})
    @content

@mixin laptop
  @media (max-width: #{$media-laptop})
    @content

@mixin large-laptop
  @media (max-width: #{$media-large-laptop})
    @content

@mixin mobile
  @media (max-width: #{$media-mobile})
    @content

@mixin desktop
  @media (min-width: #{$media-laptop})
    @content

@mixin except-phone
  @media (min-width: #{$media-tablet})
    @content
