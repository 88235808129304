.on-error-page
    height: 100vh
    min-height: 600px
    header, footer, .cabinet-header, .cabinet-second-navigation
        display: none

.error-page
    text-align: center
    &.block
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        min-height: 100vh
        padding: 50px 0 400px
    .picture
        margin: 0 auto
        position: relative
        max-width: 840px
        width: auto
        height: 500px
        background: url("/images/other/service.png") center center no-repeat
        background-size: contain
        .text
            color: #FFFFFF
            height: 100%
            display: flex
            flex-direction: column
            justify-content: center
            padding: 125px 20px 0 0
            @include tablet
                padding-top: 80px
            h1, h2
                font-weight: 700
            h1
                font-size: 64px
                line-height: 76px
                padding: 0
            h2
                font-size: 13px
                line-height: 16px
    .button
        margin-top: 70px
        min-width: 180px

    &.inside
        margin-top: 115px
        h1
            font-size: 30px
            line-height: 36px
        p
            font-size: 14px
            line-height: 24px
            color: $color-dark-gray
            margin-top: 20px
        .button
            margin-top: 45px

.denied-page
    background: url("/images/other/denied.svg") top center no-repeat
    background-size: cover
    margin: 0 -30px
    color: #ffffff
    header
        background: transparent
        margin-left: 30px
        box-shadow: none
    h1
        font-weight: 700
        font-size: 64px
        line-height: 76px
        margin-bottom: 50px
        margin-top: 19vh
    h3
        font-size: 15px
        line-height: 20px
        max-width: 420px
        margin-bottom: 50px
    .picture
        width: 100%
        height: 100vh
        display: flex
        align-items: center
        flex-direction: column
        text-align: center
